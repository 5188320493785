import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";

import AuthService from "components/AuthService/AuthService.js";


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      gcid: "",
      gcidError: true,
      gcidLogin: "",
      gcidErrorLogin: "",
      password: "",
      passwordError: true,
      passwordLogin: "",
      passwordErrorLogin: ""
    }
    this.auth = new AuthService()
  };

  componentDidMount() {
    var currentGcid = this.auth.getUser();
    if ((currentGcid != "") && (currentGcid != null)){
      this.setState({gcid: currentGcid});
    }
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  handleLoginSubmit() {
    if (this.state.gcid.length < 2) {
      this.setState({
        gcidErrorLogin: (
          <small className="text-danger">
            your glasschain id must be filled in. e.g. <i>dmo</i>.
          </small>
        )
      });
      this.setState({ gcidError: false });
    }
    else {
      this.setState({ gcidErrorLogin: null });
      this.setState({ gcidError: false });
    }
    if (this.state.password.length < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
            </small>
        )
      })
    }
    else {
      this.setState({ passwordErrorLogin: null });
      this.setState({ passwordError: false });
    }


    if ((!this.state.gcidError) && (!this.state.passwordError)) {
      // we can try to authorize
      this.auth.login(this.state.gcid, this.state.password)
        .then(result => this.auth.loggedIn())
        .then(isLoggedIn => {
            if (isLoggedIn){
              this.props.history.replace('/');
            }
            else {
              this.setState({gcidError: true});
              this.setState({ passwordError: true });
              this.setState({
                passwordErrorLogin: (
                  <small className="text-danger">
                    Your gcid and password could not be authorized.
                  </small>
                )
              });
            }
        })        
        .catch(err => {
          // write to the screen
          this.setState({passwordError: err.toString()});
          this.setState({
            passwordErrorLogin: (
              <small className="text-danger">
                There was a problem logging in [{this.state.passwordError}];
                </small>
            )
          });
        })
    }
  }


  handleGcidChange(event) {
    this.setState({
      gcid: event.target.value
    });
    if (event.target.value.length < 2) {
      this.setState({
        gcidErrorLogin: (
          <small className="text-danger">
            your glasschain id must be filled in. e.g. <i>dmo</i>.
          </small>
        )
      });
      this.setState({ gcidError: true });
    }
    else {
      this.setState({ gcidErrorLogin: null });
      this.setState({ gcidError: false });
    }
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
    if (event.target.value.length < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
            </small>
        )
      });
      this.setState({ passwordError: true });
    }
    else {
      this.setState({ passwordErrorLogin: null });
      this.setState({ passwordError: false });
    }
  }





  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="log into glasschain"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Glasschain Id</ControlLabel>
                      <FormControl
                        placeholder="Enter company glasschain id"
                        type="text"
                        default = {this.gcid}
                        onChange={event => this.handleGcidChange(event)}
                      />
                      {this.state.gcidErrorLogin}
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="glasschain password"
                        type="password"
                        autoComplete="off"
                        onChange={event => this.handlePasswordChange(event)}
                      />
                      {this.state.passwordErrorLogin}
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button
                    bsStyle="info"
                    fill
                    wd
                    onClick={this.handleLoginSubmit.bind(this)}
                  >
                    Login
                    </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
