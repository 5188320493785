

export default class TelemetrySearchParams{
    constructor(params){
        this.maxCount = "";
        this.transactionId = "";
        this.successful =  "";
        this.didPostEvents =  "";
        this.eventType =  "";
        this.queryString = "";

        this.toQueryString = this.toQueryString.bind(this);
        this.addToQueryString = this.addToQueryString.bind(this);
    }

    addToQueryString(p, name){
        if (p == null) return;
        if (p=="") return;
        if (p.toString().toUpperCase()=="ALL")return;
        if (p!=""){
            let qPiece = name + "=" + p.toString();
            if (this.queryString !=""){
                this.queryString = this.queryString + "&" + qPiece;
            }
            else {
                this.queryString = qPiece;
            }
        }
    }

    toQueryString(){
        // there are definitely better ways!
        this.addToQueryString(this.maxCount, "maxcount");
        this.addToQueryString(this.correlationId, "transactionid");
        this.addToQueryString(this.successful, "successful");
        this.addToQueryString(this.didPostEvents, "didpostevents");
        this.addToQueryString(this.eventType, "eventtype");
        return this.queryString;
    }


    validate(){
        return true;
    }
}