
import React, { Component, useCallback } from "react";
// react component used to create charts
import SweetAlert from "react-bootstrap-sweetalert";

import Button from "components/CustomButton/CustomButton.jsx";
import AuthService from '../../../components/AuthService/AuthService.js';
import GlasschainHttpClient from '../../../components/ApiService/GlasschainHttpClient.js';

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null
    };
    this.auth = new AuthService();
    this.apiClient = new GlasschainHttpClient();
  }

  isValidated() {
    return true;
  }

 


  async postSuccess() {
    var text = this.hasPropVal('subwiz-text') ? this.getPropVal('subwiz-text') : '';
    var retailer = this.hasPropVal('subwiz-alertRetailer') ? this.getPropVal('subwiz-alertRetailer') : '';
    var orderNbr = this.hasPropVal('subwiz-alertOrderNbr') ? this.getPropVal('subwiz-alertOrderNbr') : '';
    var startDt = this.hasPropVal('subwiz-alertStartDate') ? this.getPropVal('subwiz-alertStartDate') : '';
    var endDt = this.hasPropVal('subwiz-alertEndDate') ? this.getPropVal('subwiz-alertEndDate') : '';

    var identifierType = (orderNbr != '') ? 'ordernbr' :
      (retailer != '') ? 'retailer' : 'all';
    var identifierValue = (identifierType == 'ordernbr') ? orderNbr :
      (identifierType == 'retailer') ? retailer : '';

    var postObj = {
      customerGcId: this.auth.getUser(),
      transportType: "text",
      transportAddress: text,
      event: "shipment",
      identifierType: identifierType,
      identifierValue: identifierValue,
      startDate: startDt,
      endDate: endDt
    }
    var result = await this.apiClient.postSubscriptionAlert(postObj);
    this.successAlert();
  }

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Complete"
          onConfirm={() => {
            this.setState({
              alert: null
            });
            //this.props.history.push('/alertlist');
            }
          }
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
        >
          You have now subscribed to alerts!
        </SweetAlert>
      )
    });
  }



  hasPropVal(propName) {
    let propVal = this.auth.getKey(propName);
    if (propVal == null) return false;
    if (propVal == 'null') return false;
    if (propVal == 'undefined') return false;
    if (propVal == '') return false;
    if (propVal.toLowerCase() == 'all') return false;
    return true;
  }

  getPropVal(propName) {
    return this.auth.getKey(propName);
  }

  render() {

    var htmlProps = [
      { propName: 'subwiz-text', propTitle: 'Phone', propHtml: null },
      { propName: 'subwiz-alertEvent', propTitle: 'Event', propHtml: null },
      { propName: 'subwiz-alertRetailer', propTitle: 'Retailer', propHtml: null },
      { propName: 'subwiz-alertOrderNbr', propTitle: 'Order', propHtml: null },
      { propName: 'subwiz-alertStartDate', propTitle: 'Start', propHtml: null },
      { propName: 'subwiz-alertEndDate', propTitle: 'End', propHtml: null }
    ];

    var htmlRender = [];
    for (let i = 0; i < htmlProps.length; i++) {
      if (this.hasPropVal(htmlProps[i].propName)) {
        let x = htmlProps[i].propTitle + ": " + this.getPropVal(htmlProps[i].propName);
        htmlRender.push(x);
      }
    }


    return (
      <div className="wizard-step">
        <h2 className="text-center text-space">
          Almost There
          <br />
          <small>
            {" "}
            Confirm and Click on "<b>Finish</b>" to complete subscription
          </small>
        </h2>
        <div>
          <ul>
            {htmlRender.map((item, i) => <li key={item}>{item}</li>)}
          </ul>
        </div>
        <div className="wizard-finish-button">
          <Button
            bsStyle="info"
            fill
            wd
            onClick= {this.postSuccess.bind(this)}
            pullRight
          >
            Finish
          </Button>
        </div>
        {this.state.alert}
      </div>
    );
  }
}

export default Step3;
