import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';

import ValueAddIcon from "../../assets/img/logos/value-added-icon.png";



const post_data_item_columns = 
[
    { dataField: 'item.itemId', text: 'item' },
    { dataField: 'item.gtin', text: 'gtin' },
    { dataField: 'item.uom', text: "uom" },
    { dataField: 'totalQty', text: "qty" }
]


const block_columns = 
[
    { dataField: 'lotInfo.ranchNbr', text: 'ranch' },
    { dataField: 'lotInfo.ranchName', text: 'Name' },
    { dataField: 'lotInfo.blockDesignator', text: 'Block', formatter: blockCellFormatter },
    { dataField: 'lotInfo.lotDesignator', text: 'Name', formatter: lotInfoFormatter },
    { dataField: 'lotInfo.harvestDate', text: 'harvested', formatter: dtFormatter },
    { dataField: 'qty', text: 'qty' }
];

const v2_harvest_block_columns = 
[
    { dataField: 'harvestLot.growerName', text: 'Grower' },
    { dataField: 'harvestLot.ranchName', text: 'Ranch' },
    { dataField: 'harvestLot.blockId', text: 'Block', formatter: blockCellFormatter },
    { dataField: 'harvestLot.lotId', text: 'Lot', formatter: lotInfoFormatter },
    { dataField: 'harvestLot.harvestDate', text: 'harvested', formatter: dtFormatter },
    { dataField: 'qty', text: 'qty' }
];

const v2_purchase_block_columns = 
[
    { dataField: 'batchNbr', text: 'batch reference' },
    { dataField: 'batchDate', text: 'batched', formatter: dtFormatter },
    { dataField: 'qty', text: 'qty' }
];


function blockCellFormatter(cell, row) {
    
    if (row.isMixed) {
            return <span className='align-middle'><img src={require(`../../assets/img/logos/${'value-added'}-icon.png`)} height='45px' width='45px' alt='Value-added'/></span>
    } else {
        return (
            <span>{cell}</span>
        );
    }
    
}

function lotInfoFormatter(cell, row) {
    if (row.isMixed) {
        if (row.mixInfo.mixTransactionId != null) {
            var runDt = row.mixInfo.mixTransactionDate.slice(0, -15); // remove UTC offset and seconds
            return (
                <span>RunId: {row.mixInfo.mixTransactionId} ({row.mixInfo.mixTransactionDescript}) on {runDt}</span>
            );
        }
    } else {
        if (!cell){
            return (<span>{row.batchNbr}</span>)
        }
        else {
        return (
            <span>{cell}</span>
        );
        }
    }
}

function dtFormatter(cell, row) {
    if (!cell){
        console.log("row: ");
        console.log(row);
        if (!row.harvestLot){
            var newCellVal = row.batchDate.slice(0,-15);
            return (<span>{newCellVal}</span>) 
        }
        // maybe a mixed lot?
        return (
            <span>&nbsp;</span>
        );
    }
    var cellVal = cell.slice(0, -15); // remove UTC offset and seconds
    return (
        <span> {cellVal}</span>
    );
}


export default class OrderDetailItemTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: [0, 1] };
    }



    handleBtnClick = () => {
        if (!this.state.expanded.includes(2)) {
            this.setState(() => ({
                expanded: [...this.state.expanded, 2]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== 2)
            }));
        }
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                expanded: [...this.state.expanded, row.id]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.id)
            }));
        }
    }

    render() {

        console.log("orderDetail.items: ");
        console.log(this.props.orderDetail.items);

        // figure out what kind of detail to show based on if this is a purchase or harvest block
      


        const expandRow = {
            renderer: row => (
                <div>
                    <p><i>{row.item.description}</i></p>
                    <BootstrapTable keyField="id" 
                    data={row.lotQtys} 
                    columns={(row.lotQtys[0].lotDesignator) ? 
                        block_columns: 
                        (row.lotQtys[0].harvestLot) ? 
                            v2_harvest_block_columns:
                            v2_purchase_block_columns
                    } />
                </div>
            ),
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand
        };
        return (
            <div>
                <BootstrapTable keyField="id" data={this.props.orderDetail.items} columns={post_data_item_columns} expandRow={expandRow} />
            </div>
        );
    }
}