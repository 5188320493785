import React, { Component } from "react";




export default class DateTimeCellRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //value: props.value
        }
    }

    formatIsoDateTime(value) {
        var cellVal = value.slice(0, -9).replace('T', ' '); // remove UTC offset and seconds
        return (
          <span> { cellVal }</span>
        );
    }

    refresh(){
        return false; // tell underlying grid to reconstruct
    }

    render() {
        return (
            <span>{this.formatIsoDateTime(this.props.value)}</span>
        );
    }
};