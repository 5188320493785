import decode from 'jwt-decode';
import AppConfig from '../AppConfig/AppConfig.js';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.appConfig = new AppConfig();
        this.domain = domain || this.appConfig.getAuthServiceHost(); 
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.expiresInMilliseconds = this.expiresInMilliseconds.bind(this);
        this.expirationDateTime = this.expirationDateTime.bind(this);
    }

    login(gcid, secret) {
        var params = {
            grant_type: 'client_credentials',
            client_id: gcid,
            client_secret: secret,
            scope: 'dashboard'
        }; 
        const formBody = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
        return fetch(`${this.domain}/connect/token`, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            method: 'POST',
            body: formBody
          })
          .then(res => this._checkStatus(res))
          .then(checkedRes => checkedRes.json())
          .then(jsonRes => {
            this.setUser(gcid);
            this.setToken(jsonRes.access_token) // Setting the token in localStorage
            return Promise.resolve(jsonRes);
        })
        .catch((error) => {
          console.log(error);
          this.logout();
          return Promise.reject(error);
        });
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300){ // Success status lies between 200 to 300
            return response;
        }  
        else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return true;
        }
    }

    expirationDateTime(){
        //in milliseconds
        var profile = this.getProfile();
        return profile.exp * 1000;
    }

    expiresInMilliseconds(){
        var profile = this.getProfile();
        var expireDtTime = new Date(profile.exp*1000);
        var now = new Date();
        var result = expireDtTime.getTime() - now.getTime();
        return result;
    }

    setKey(key, val){
        localStorage.setItem(key, val);
    }

    getKey(key){
        return localStorage.getItem(key);
    }

    keyExists(key){
        return (this.getKey(key)!=null);
    }

    setUser(gcid){
        localStorage.setItem('user_gcid', gcid);
    }

    getUser(){
        return localStorage.getItem('user_gcid');
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    setTimezone(timezone){
        localStorage.setItem("timezone", timezone)
    }

    getTimezone(){
        localStorage.getItem("timezone")
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        var token = this.getToken();
        var result = decode(token);
        return result;
    }


    fetch(url, options, overrideHeaders) {
        // performs api calls sending the required authentication headers
        const headers =  overrideHeaders || {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }


}