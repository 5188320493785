/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import image from "assets/img/full-screen-image-3.jpg";

// dinamically create dashboard routes
import routes from "routes.js";

// style for notifications
import { style } from "variables/Variables.jsx";

import AuthService from "components/AuthService/AuthService.js";
import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";
var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _notificationSystem: null,
      notificationText: "",
      notificationMenuItems: ["Your Notifications"],
      gcidRegistry: null,
      lastPostedNbr: "",
      lastPostedDateTime: "",

      image: image,
      color: "black",
      hasImage: false,
      navbar: false,
      mini: true,
      fixedClasses: "dropdown show-dropdown open"
    };

    this.auth = new AuthService();
    this.coreClient = new GlasschainHttpClient();

    this.checkLastPostedForNotification = this.checkLastPostedForNotification.bind(
      this
    );
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
  }

  /*
  UNSAFE_componentWillMount() {
    // Here's where we check authentication
    if (!this.auth.loggedIn()) {
      this.props.history.replace("/auth/login-page");
    }
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  */



  async componentDidMount() {
    // Here's where we check authentication
    if (!this.auth.loggedIn()) {
      this.props.history.replace("/auth/login-page");
    }
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }

    var registry = await this.coreClient.fetchGcidRegistry();
    this.auth.setTimezone(registry.data.senderConfig.timeZoneDesignator);
    this.setState({gcidRegistry: registry.data});


    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    // init notification system
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.interval = setInterval(() => {
      this.checkLastPostedForNotification();
    }, 120000);

    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  }

  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    clearInterval(this.interval);
  }

  async checkLastPostedForNotification() {
    var response = await this.coreClient.fetchLastPostedBatchTrace();
    if (response.data.docMasterNbr == this.state.lastPostedNbr) {
      return;
    }
    this.setState({
      lastPostedNbr: response.data.docMasterNbr,
      lastPostedDateTime: response.data.eventDateTimeLocal,
      notificationText:
        "Last Posted: " +
        response.data.docMasterNbr +
        "(" +
        response.data.eventDateTimeLocal +
        ")"
    });
    this.handleNotificationClick(4);
    var newNotificationMenuItem = response.data.docMasterNbr;
    this.setState(prevState => ({
      notificationMenuItems: [
        ...prevState.notificationMenuItems,
        newNotificationMenuItem
      ]
    }));
  }

  handleNotificationClick(color) {
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-science" />,
      message: (
        <div>
          <b>{this.state.notificationText}</b>
        </div>
      ),
      level: level,
      position: "tr",
      autoDismiss: 3
    });
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleNavbarClick = navbar => {
    this.setState({ navbar: navbar });
  };
  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={routeProps => (
              <prop.component
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
        />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <AdminNavbar
            {...this.props}
            registry = {this.state.gcidRegistry}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
            notificationMenuItems={this.state.notificationMenuItems}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;
