import React, { Component } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import RetailerFlagCellRenderer from './AgGridRenderers/RetailerFlagCellRenderer.jsx';
import DateTimeCellRenderer from './AgGridRenderers/DateTimeCellRenderer.jsx';
import BoolCellRenderer from './AgGridRenderers/BoolCellRenderer.jsx';
import BoolCellTrueOnlyRenderer from './AgGridRenderers/BoolCellTrueOnlyRenderer.jsx';
import ZipCellRenderer from './AgGridRenderers/ZipCellRenderer';

import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";


export default class PostDocumentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            currPage: 1,
            columnDefs: [
                { headerName: "Cust", field: "retailerGcId", minwidth: 60, resizable: true, width: 80, cellRendererFramework: RetailerFlagCellRenderer },
                { headerName: "Order", field: "docMasterNbr", minWidth: 80, resizable: true, width: 100, sortable: true, filter: true },
                { headerName: "Posted", field: "eventDateTimeLocal", minWidth: 100, resizable: true, width: 150, sortable: true, filter: true, cellRendererFramework: DateTimeCellRenderer },
                { headerName: "Result", field: "status", minWidth: 60, resizable: true, width: 70, sortable: true, filter: true, cellRendererFramework: BoolCellRenderer },
                { headerName: "Environ", field: "ftPostEnviron", minWidth: 80, resizable: true, width: 80, sortable: true, filter: true },
                { headerName: "Mixed", field: "hasMixedLots", minWidth: 60, resizable: true, width: 60, sortable: true, filter: true, cellRendererFramework: BoolCellTrueOnlyRenderer},
                { headerName: "Id", field: "correlationId", hide: true },
                { headerName: 'Actions', field: "value", colId: "params", width: 80, cellRendererFramework: ZipCellRenderer }
            ],
            context: { componentParent: this }
        };
        this.dataClient = new GlasschainHttpClient();

        this.rowSelectionChangedHandler = this.rowSelectionChangedHandler.bind(this);

    }



    methodFromParent(rowIndex) {
        let cid = this.props.summaryList[rowIndex].correlationId;
        //this.dataClient.fetchZippedAssets(cid);
        this.dataClient.downloadZippedAssets(cid);
        //await this.dataClient.fetchZippedAssets(cid);
        console.log("CorrelationId is " + cid + "!");
    }



    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridSizeChanged(params) {
        var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
        var columnsToShow = [];
        var columnsToHide = [];
        var totalColsWidth = 0;
        var allColumns = params.columnApi.getAllColumns();
        for (var i = 0; i < allColumns.length; i++) {
            let column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }
        //params.columnApi.setColumnsVisible(columnsToShow, true);
        //params.columnApi.setColumnsVisible(columnsToHide, false);
        params.api.sizeColumnsToFit();
    }



    rowSelectionChangedHandler(row) {
        var selectedRows = this.gridApi.getSelectedRows();
        var selectedRow = selectedRows[0];
        var orderNbr = selectedRow.docMasterNbr; // don't use docMasterNbr! That will return just the order, not necessarily the correct transaction!
        var correlationId = selectedRow.correlationId;
        var result = this.props.summaryList.find(obj => {
            return obj.correlationId === correlationId
        });
        var data = result.orderSummary;
        this.props.onOrderDetailChange(data); // lifts state up to dashboard
    };







    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
                    <div
                        className="ag-theme-balham"
                        style={{
                            height: "480px",
                            width: "100%"
                        }}
                    >
                        <AgGridReact
                            rowSelection="single"
                            context={this.state.context}
                            columnDefs={this.state.columnDefs}
                            enableRangeSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowData={this.props.summaryList}
                            animateRows={true}
                            //rowSelection={this.state.rowSelection}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.rowSelectionChangedHandler}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}

                        />
                    </div>
                </div>
            </div>
        );



    }


}