import React from "react";
import Tree from "react-d3-tree";
import NodeLabel from '../Trees/NodeLabel.jsx';



const containerStyles = {
  width: '100%',
  height: '100vh',
}

export default class TraceTree extends React.PureComponent {

    state = {
    };
 

  componentDidMount() {
    const dimensions = this.treeContainer.getBoundingClientRect();
    this.setState({
      translate: {
        x: dimensions.width / 2,
        y: dimensions.height / 2
      }
    });
  }


  async onMouseClick(node, evt){
    console.log("mouseClick", this);

    // Okay, we have a few things that MUST be true for us to push this node.
    // The node.name MUST have a value AND the evt.target.nodeName MUST BE "circle" or rect or div. That is a TARGET hit
    if (node.name == "") return false;
    let evtTargetType = evt.target.nodeName.toLowerCase();
    if (evtTargetType=="button") return true; // expand/contract
    if (evtTargetType != "circle" && evtTargetType!="rect" && evtTargetType!="div") return false;
    var transactionId = node.transactionId;
    await this.props.onTraceTreeNodeUpdate(transactionId);
    return false;
  }

  render() {
    return (

      <div style={containerStyles} ref={tc => (this.treeContainer = tc)}>
        <Tree 
          data={this.props.data} 
          translate={this.state.translate} 
          orientation={'vertical'}
          onClick={this.onMouseClick.bind(this)}
          allowForeignObjects
          nodeLabelComponent={{
            render: <NodeLabel className='myLabelComponentInSvg' />,
            foreignObjectWrapper: {
              y: 24
            }
          }}
        />
      </div>
    );
  }
}
