

export default class TelemetryHealthStatus{
    constructor(isHealthy, healthState, healthText, lastPolledTime, lastPolledMessage){
        this.isHealthy = isHealthy || false;
        this.healthState = healthState || ""; // "unknown, success, warning, danger  
        this.healthText = healthText || "testing";
        this.lastPolledTime = lastPolledTime || "never"; // should be iso8601 datetime
        this.lastPolledMessage = lastPolledMessage || "";
    }



}