
import React, { Component } from "react";
import {Row,Col,Nav,NavItem,Tab} from "react-bootstrap";

import FormInputs from "components/FormInputs/FormInputs.jsx";
import Card from "components/Card/Card.jsx";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactTooltip from 'react-tooltip';

import OrderDetailItemTable from 'components/Card/OrderDetailItemTable.jsx';
import OrderDetailSummary from 'components/Card/OrderDetailSummary.jsx';

import TraceTree from "components/Trees/TraceTree.jsx";

import GoogleMaps from "../../views/Maps/GoogleMaps.jsx"




class OrderDetailCard extends Component {
    constructor(props) {
        super(props);
    }


    componentDidUpdate(e) {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            //this._reactInternalInstance._currentElement._owner._instance._reactInternalInstance._currentElement._owner._instance.componentDidUpdate(
            //    e
            //);
        }
    }
    isMac() {
        let bool = false;
        if (
            navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
            navigator.platform.toUpperCase().indexOf("IPAD") >= 0
        ) {
            bool = true;
        }
        return bool;
    }




    render() {


        const docTable = (
            <OrderDetailItemTable
                orderDetail = {this.props.orderDetail}
            />
        );

        const tabsIcons = (
            <Tab.Container id="tabs-with-dropdown" defaultActiveKey="info">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="tabs">
                            <NavItem eventKey="order">
                                <i className="fa fa-reorder" /> Order
                            </NavItem>
                            <NavItem eventKey="items">
                                <i className="fa fa-opencart" /> Items
                            </NavItem>
                            <NavItem eventKey="tree">
                                <i className="fa fa-tree" /> Tree
                            </NavItem>
                            <NavItem eventKey="map">
                                <i className="fa fa-map-pin" /> Map
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm={12}>
    
                        <Tab.Content animation>
                            <Tab.Pane eventKey="order">
                                <OrderDetailSummary
                                    orderDetail = {this.props.orderDetail}
                                />
                                <div className="clearfix" />
                            </Tab.Pane>
                            <Tab.Pane eventKey="items">
                                {docTable}
                            </Tab.Pane>
                            <Tab.Pane eventKey="tree">
                                <TraceTree
                                    data = {this.props.treeData}
                                    onTraceTreeNodeUpdate = {this.props.onTraceTreeNodeUpdate}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="map">
                                <GoogleMaps
                                    orderDetail = {this.props.orderDetail}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );

        return (
                            <Card
                                title=""
                                category=""
                                ctFullWidth
                                content={tabsIcons}
                            />
  
        );
    }
}

export default OrderDetailCard;
