// "https://gc-auth-svc.azurewebsites.net"
// "https://gc-core-svc.azurewebsites.net"
// "http://localhost:6001"
// "http://localhost:7001"

var appsettings = [
    {key: "glasschain.auth.host.addr", value: "https://gc-auth-svc.azurewebsites.net"},
    {key: "glasschain.core.host.addr", value: "https://gc-core-2-svc.azurewebsites.net"},
    //{key: "glasschain.auth.host.addr", value: "http://localhost:6001"},
    //{key: "glasschain.core.host.addr", value: "http://localhost:7001"},
    {key: "version", value: "v2.02.08"},
    {key: "location.documents.userguide", value: "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/glasschain-ibmft-userguide.pdf"}
]

module.exports = {
    appsettings
}