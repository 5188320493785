
import React, { Component } from "react";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

function diffMinutes(dt2, dt1) 
{
 var diff =(dt2.getTime() - dt1.getTime()) / 1000;
 diff /= 60;
 return Math.abs(Math.round(diff));
 
}

export default class SystemHealthCard extends Component {
    constructor(props) {
        super(props);
    }


    fetchIconStyle(status){
        if (status.toLowerCase()=="testing"){
            status = "success";
        }
        return 'text-' + status.toLowerCase();
    }


    render() {
        let myHealthState = this.props.healthState;
        const myStyleClass = "pe-7s-server text-" + myHealthState.toLowerCase() + "";
        const myTextStyleClass = "" + myHealthState.toLowerCase() + "";
        let lastCheckedMinutesAgo = diffMinutes(new Date(), this.props.lastHealthCheckDateTime);
        let lastCheckedAgoString = "unknown";
        if (lastCheckedMinutesAgo > (24*60)){
            lastCheckedAgoString = "more than one day ago"
        }
        else {
            lastCheckedAgoString = lastCheckedMinutesAgo + " minutes ago"
        }

        return (
            <div className="card card-stats">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="icon-big text-center icon-warning">
                                <Tippy animation="fade" theme="bootstrap" content={this.props.bigIconText}>
                                <i className={myStyleClass} />
                                </Tippy>
                            </div>
                        </div>
                        <div className="col-xs-7">
                            <div className="numbers">
                                <p>{this.props.statsText}</p>
                                <span classname={myTextStyleClass}>{this.props.statsValue.toLowerCase()}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <hr />
                    <div className="stats">
                    <i className="fa fa-refresh" /> {lastCheckedAgoString}
                    </div>
                </div>
            </div>
        );
    }
}


