import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import AppConfig from "components/AppConfig/AppConfig.js";
 
export default class UserHelpPage extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.appConfig = new AppConfig();

    this.state = {
      numPages: null,
      pageNumber: 1
    };

    this.userGuideUrl = this.appConfig.getUserGuideUrl();


  }


  
    onDocumentLoadSuccess = (document) => {
      const { numPages } = document;
      this.setState({
        numPages,
        pageNumber: 1,
      });
    };
  
    changePage = offset => this.setState(prevState => ({
      pageNumber: prevState.pageNumber + offset,
    }));
  
    previousPage = () => this.changePage(-1);
  
    nextPage = () => this.changePage(1);
  
  render() {
    const { numPages, pageNumber } = this.state;

    return (
      <div>
      <React.Fragment>
        <Document
          file={this.userGuideUrl}
          onLoadSuccess={this.onDocumentLoadSuccess.bind(this)}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div>
          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={this.previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={this.nextPage}
          >
            Next
          </button>
        </div>
      </React.Fragment>
      </div>
    );
  }
}