
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Wizard from "views/Forms/SubscriptionWizard/Wizard.jsx";
import UserPage from "views/Pages/UserPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import SubscriptionPage from 'views/Pages/SubscriptionPage.jsx';
import UserHelpEmailPage from 'views/Pages/UserHelpEmailPage.jsx';
import UserHelpPage from 'views/Pages/UserHelpPage.jsx';
import UploadDataPage from 'views/Pages/UploadDataPage.jsx';
import LogsPage from 'views/Pages/LogsPage.jsx';
import MasterDataPage from 'views/Pages/MasterDataPage.jsx';
import PrivacyPage from 'views/Pages/PrivacyPage.jsx';
import Trace from "views/Components/Trace.jsx";


var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "pe-7s-graph",
    routetype: "route",
    component: Dashboard
  },

  {
    collapse: true,
    path: "/subscriptions",
    name: "Alerts",
    state: "openComponents",
    icon: "pe-7s-speaker",
    routetype: "route",
    views: [
      {
        path: "/alerts",
        layout: "/admin",
        name: "Subscribe",
        mini: "AS",
        routetype: "route",
        component: Wizard
      },
      {
        path: "/alertlist",
        layout: "/admin",
        name: "Alert List",
        mini: "AL",
        routetype: "route",
        component: SubscriptionPage
      }
    ]
  },
  {
    collapse: true,
    path: "/help",
    name: "Help",
    state: "openHelp",
    icon: "pe-7s-light",
    routetype: "route",
    views: [
      {
        path: "/userguide",
        layout: "/admin",
        name: "User Guide",
        mini: "UG",
        routetype: "route",
        component: UserHelpPage
      },
      {
        path: "/mailsupport",
        layout: "/admin",
        name: "Contact Support",
        mini: "CS",
        routetype: "route",
        component: UserHelpEmailPage
      },
      {
        path: "/privacy-page",
        layout: "/auth",
        name: "Privacy Policy",
        mini: "PP",
        routetype: "route",
        component: PrivacyPage
      }
    ]
  },
  {
    collapse: true,
    path: "/data",
    name: "Data",
    state: "openData",
    icon: "pe-7s-cloud-upload",
    routetype: "route",
    views: [
      {
        path: "/upload",
        layout: "/admin",
        name: "Upload Data",
        mini: "UD",
        routetype: "route",
        component: UploadDataPage
      },
      {
        path: "/master",
        layout: "/admin",
        name: "Master Data",
        mini: "MD",
        routetype: "route",
        component: MasterDataPage
      },
      {
        path: "/logs",
        layout: "/admin",
        name: "Logs",
        mini: "LOG",
        routetype: "route",
        component: LogsPage
      }
    ]
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "pe-7s-gift",
    routetype: "page",
    views: [
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        routetype: "page",
        component: LoginPage
      }
    ]
  }
];
export default routes;
