import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";


export default class PrivacyPage extends React.Component {
  render() {

    const bkStyle =  {
      backgroundColor: 'rgba(250, 250, 250, 0.6)',
      padding: '15px',
      borderRadius:'10px 10px 10px 10px'
  }
    return (

      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
            <div 
              style={bkStyle}
              >

            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Privacy Policy</Media.Heading>
                Glassbit, LLC (which may be referenced in this statement as “Glassbit”, “Glasschain”, the “Company”, “we” or “us”) considers 
                respect for our customers’ privacy a core value. Our application (Glasschain) is, by nature, a collaborative platform that 
                acts as a conduit to share data between you and the partners you have selected to work with. Even within those parameters, at your 
                request we can obfuscate certain data elements so that, while they meet the requirements of the collaboration, they are
                not identifiable to your partners. Examples include ranch numbers and addresses.
                <br/>
                <br/>
                This Privacy Policy Statement describes how and where we collection information from and about you and your organization, 
                how we store that information and how we share it with your company and your partners. By accessing this website application, 
                you agree to this Privacy Policy. This may be in addition to other specific agreements we have with you. In the event that such 
                agreements conflict with this Privacy Policy, the terms of those specific agreements will take precedence. 
              
              </Media.Body>
            </Media>

            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Your Privacy</Media.Heading>
                Glasschain is a business-to-business SaaS application. We are NOT in the business of collecting, aggregating or selling
                personally-identifying information. In fact we collect very little data about individuals. 
                <br/>
                <br/>
                In addition, we are not interested in leveraging our customers' information beyond the scope of what you're paying us for.
                That means we do not monetize your information for third-party commerce. We do not share your information outside of the 
                functionality of the Glasschain SaaS service.  
              </Media.Body>
            </Media>


            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-graph1" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Information Collection</Media.Heading>
                We collect information from you via:
                <ul>
                  <li>
                    Sign up and Configuration to our services.<br/>
                    When you sign on to use Glasschain services, we have certain information required to configure your company to work with 
                    partner companies. 
                  </li>
                  <li>
                    Accessing certain optional features.<br/>
                    Some optional features - for example, event alerting - require personally-identifying information like phone number and/or email address.
                  </li>
                  <li>
                  Use of our services, including the Glasschain adapter, core "exchange" service and the dashboard you're looking at now.
                  <br/> 
                  In addition to business transaction data that you share with your partners, Glasschain also captures “metadata” about your transactions.
                  This is primarily non-personally-identifying information of the sort that web browsers, 
                  servers, and maintenance and monitoring systems typically make available, such as the browser type, language preference, referring site, 
                  and the time of each visit. Other non-identifying information that we might have access to includes how you use the Service 
                  (e.g. search queries). We collect this non-personally-identifying information in order to better understand how visitors use the 
                  Services and, where possible, to improve their experience. For instance, we log the time it takes to run database queries so that 
                  we can improve performance. In some cases, we may publicly display information that is not personally identifying in the aggregate, 
                  (e.g., by publishing a report on trends in the usage of our Services). We do not provide this aggregate data to third parties. 
                  </li>
                </ul>
                
                <br/>
                <br/>
                When you use the Services, we also collect potentially personally identifying information in the form of Internet Protocol (IP) addresses and 
                the Uniform Resource Locator (URL) accessed. We don't use that information to identify you, with one exception: we may discover, by 
                reviewing log files, that a particular account is using the Services in a way that is degrading the experience for all the Services’ users. 
                If this is discovered, we may contact your company to help understand what might be causing the problem. 
                <br/>
                <br/>
                Third Party Access to your information<br/>
                We only share information with partners that you, as a company, have selected to share with (e.g. IBM Food Trust retailers). 
                In addition, we use cloud providers to host our services. While the providers have access to our systems for maintenance, they do not 
                have access to your data.

              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>How We Protect Your Information</Media.Heading>
                Glassbit uses industry-standard technologies when transferring and receiving data exchanged between Glasschain and other companies as
                well as between Glasschain and you as a Dashboard user.
                This site has security measures in place to help protect information under our control from the risk of 
                accidental or unlawful destruction or accidental loss, alteration or unauthorized disclosure or access. However, “perfect security” 
                does not exist on the Internet.  Because Glasschain acts as an intermediary between systems, we make an effort to vet the security 
                in place in these systems. But we cannot and do not take responsibility for the security of these systems, including your own. But we
                make information on all partners (e.g. hosting services like Microsoft Azure) available to you upon request. Contact support@glassbit.com
                to request this information.
              </Media.Body>
            </Media>

            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Cookies</Media.Heading>
                Many of our web pages use “cookies.” Cookies are text files we place in your computer’s browser to store your preferences. We do 
                NOT use cookies to identify you as an individual. Instead, these allow us to route your traffic efficiently across our network. 
                We also use local storage to keep limited-time access keys to your account. These keys only identify your session for logging in and 
                accessing resources securely. We do not use cookies to track you across sites or to identify you for marketing opportunities. 
                We may use cookies to provide you with online support, though you may choose alternative 
                methods for contacting us. You have many choices with regards to the management of cookies on your computer. All major browsers 
                allow you to block or delete cookies from your system. To learn more about your ability to manage cookies, please consult the 
                privacy features in your browser.
                <br/>
                <br/>
                Data Analytics<br/>
                We do not use analytics “trackers” or “beacons” on our dashboard, other than for the purposes discussed above. 
                We’ve made a decision that your privacy is more important to us than these kinds of analytics. 
                </Media.Body>
            </Media>

            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-headphones" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Limiting Use, Disclosure, Retention </Media.Heading>
                Glassbit identifies the purposes for which the information is being collected before or at the time of collection. The collection 
                of your personal data will be limited to that which is needed for the purposes identified by Glassbit. Unless you consent, or we 
                are required by law, we will only use the personal data for the purposes for which it was collected. If Glassbit will be 
                processing your personal data for another purpose later on, Glassbit will seek your further legal permission or consent; 
                except where the other purpose is compatible with the original purpose. We will keep your personal data only as long as 
                required to serve those purposes. We will also retain and use your personal data for as long as necessary to comply with 
                our legal obligations, resolve disputes, and enforce our agreements.
                </Media.Body>
            </Media>

            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-user" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Additional Rights (EEA, UK and Switzerland only)</Media.Heading>
                If you reside in the European Economic Area, the United Kingdom or Switzerland, you may have the right to exercise additional 
                rights available to you under applicable laws, including:<br/>
                <ul>
                  <li>
                    Right of erasure.<br/>
                    You may have a broader right to erasure of personal data that we hold about you. For example, if it is no longer 
                    necessary in relation to the purposes for which it was originally collected. Please note, however, that we may need to retain certain 
                    information for record keeping purposes, to complete transactions or to comply with our legal obligations, among other things.
                  </li>
                  <li>	
                    Right to object to processing.<br/>
                    You may have the right to request that we stop processing your personal data or to stop sending 
                    you alerting communications. 
                  </li>
                  <li>
                    Right to restrict processing. You may have the right to request that we restrict processing of your personal data in certain 
                    circumstances. For example, where you believe that the personal data we hold about you is inaccurate or unlawfully held.
                  </li>
                  <li>Right to data portability: In certain circumstances, you may have the right to be provided with your personal data in a structured, 
                    machine readable and commonly used format and to request that we transfer the personal data to another data controller without hindrance.
                  </li>
                </ul>
                <br/>
              If you would like to exercise any of the above rights, please contact our support team at support@glassbit.com. 
              We will consider your request in accordance with applicable laws. To protect your privacy and security, we may take steps 
              to verify your identity before complying with the request. 
              <br/>
              <br/>
              Note that we honor these rights for ALL users, regardless of geographic location. The above spells out these rights specifically
              to align with European privacy rule disclosures.
               </Media.Body>
            </Media>
                  
            </div>
          </Col>
        </Row>
      </Grid>
  
    );
  }
}

