import React, { Component } from "react";




export default class BoolCellTrueOnlyRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //value: props.value
        }
    }

    isTrue(value){
        if (value==null){
            return false;
        }
        if (typeof(value) === typeof(true)){
            return value;
        }
        else {
            // must be a string representation 
            var val = value.toLowerCase();
            return ( (val=='success') || (val=='ok') || (val=='true') || (val=='yes'));
        }
    }

    formatBoolFlag(value) {
        if (this.isTrue(value)){
            return <span><img src={require(`../../assets/img/logos/${'ok'}-icon.png`)} height='20px' width='20px' alt='Ok'/></span> 
        }
        else {
            return '';
        }
    }

    refresh(){
        return false; // tell underlying grid to reconstruct
    }

    render() {
        return (
            <span>{this.formatBoolFlag(this.props.value)}</span>
        );
    }
};