import React from 'react'
import Card from "components/Card/Card.jsx";
import { Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";
import AppConfig from "components/AppConfig/AppConfig.js";

import Mailto from 'react-protected-mailto'

export default class UserHelpEmailPage extends React.Component {

    constructor(){

        this.userGuideUrl = new AppConfig().getUserGuideUrl();

    }

    componentDidMount(){
        // Hubspot chat tool.
        /*
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.src = //js.hs-scripts.com/6026305.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        */
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <div className="header-text">
                            <h2>Glasschain Support Contact Information</h2>
                            <h4>Contact Us</h4>
                            <hr />
                        </div>
                    </Col>
                    <Col md={4} mdOffset={2}>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-user" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                <Media.Heading>Glasschain Support</Media.Heading>
                                Email us and let us know how we can help you. 
                  </Media.Body>
                        </Media>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-graph1" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                <Media.Heading>Check Out the User Guide</Media.Heading>
                                You can also check out the Glasschain User Guide by
                                clicking <a href={this.userGuideUrl} target='_blank'>Here</a> 
                                
                  </Media.Body>
                        </Media>
                        <Media>
                            <Media.Left>
                                <div className="icon">
                                    <i className="pe-7s-headphones" />
                                </div>
                            </Media.Left>
                            <Media.Body>
                                <Media.Heading>Help Us Help You</Media.Heading>
                                Please let us know who you are, your company as detailed an 
                                explanation as you can to repeat the problem. 
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                  
                  </Media.Body>
                        </Media>
                    </Col>
                    <Col md={4}>
                        <form>
                            <Card
                                plain
                                content={
                                    <div>
                                        <FormGroup>
                                            <p>
                                                Email: <Mailto
                                                    email='support@glassbit.com'
                                                    headers={
                                                        { subject: 'Glasschain Support Question' },
                                                        { cc: 'sales@glassbit.com' }
                                                    } />
                                            </p>
                                 
                                        </FormGroup>
                                   </div>
                                }
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
}




