import React, { Component } from "react";




export default class RetailerFlagCellRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //value: props.value
        }
    }

    formatRetailerFlag(value) {
        return <span><img src={require(`../../assets/img/logos/${value}-flag.png`)} alt={value}/></span>

    }

    refresh(){
        return false; // tell underlying grid to reconstruct
    }

    render() {
        return (
            <span>{this.formatRetailerFlag(this.props.value)}</span>
        );
    }
};