
import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

import MapCard from "components/Card/MapCard.jsx";




class GoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    }


    this.adjustMap = this.adjustMap.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }

  
  componentDidMount() {

  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  }

  onClose = props => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    });
  }
};

  adjustMap(mapProps, map) {
    const {google, markers} = mapProps;
    const bounds = new google.maps.LatLngBounds();
  
    markers.forEach(marker => {
      const {lat, lng} = marker.position;
  
      bounds.extend(new google.maps.LatLng(lat, lng));
    });
  
    map.fitBounds(bounds);
    // map.panToBounds(bounds);
  }

  render() {
    const shipFromLat = parseFloat(this.props.orderDetail.shipFromLat);
    const shipFromLng = parseFloat(this.props.orderDetail.shipFromLng);
    const shipFromAddr = this.props.orderDetail.shipFromFull;
    const shipToLat = parseFloat(this.props.orderDetail.shipToLat);
    const shipToLng = parseFloat(this.props.orderDetail.shipToLng);
    const shipToAddr = this.props.orderDetail.shipToFull;

    const RegularMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={13}
          defaultCenter={{ lat: shipToLat, lng: shipToLng }}
          defaultOptions={{scrollwheel: true}}
          //onReady={this.adjustMap}
          onClick = { this.onMapClick }
          onMarkerClick={this.onMarkerClick}
        >
          <Marker
            title = {'Ocean Mist'}
            position={{ lat: shipFromLat, lng: shipFromLng }}
            name={shipFromAddr}
          />
          <Marker
            title = {'Retailer'}
            position={{ lat: shipToLat, lng: shipToLng }}
            name={shipToAddr}
          />
        </GoogleMap>
      ))
    );


    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <MapCard
                title="ShipTo"
                content={
                  <RegularMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDw3PSVu3l792drqAfAf1KQ8yGNo-bb8WU"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `280px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default GoogleMaps;
