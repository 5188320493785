// Import React FilePond

import React from "react";
import {Button, Modal, Grid, Row, Col} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import AuthService from "components/AuthService/AuthService.js";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import GlasschainHttpClient from "../../components/ApiService/GlasschainHttpClient.js";
import DateTimeCellRenderer from "../../components/AgGridRenderers/DateTimeCellRenderer.jsx";
import TelemetrySearchParams from "Models/TelemetrySearchParams";
import BoolCellRenderer from "components/AgGridRenderers/BoolCellRenderer";

export default class LogsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentRow: null,
      showLogDetail: false,
      syntaxDoc: null,
      columnDefs: [
        { headerName: "Id", field: "id", hide: true },
        {
          headerName: "Polled at",
          field: "pollingEndDateTime",
          resizable: true,
          width: 150,
          cellRendererFramework: DateTimeCellRenderer
        },
        {
          headerName: "Elapsed Seconds",
          field: "pollingElapsedSeconds",
          resizable: true,
          width: 150,
          sortable: true,
          filter: true
        },
        {
          headerName: "Event Type",
          field: "eventType",
          resizable: true,
          width: 150
        },
        {
          headerName: "Target System",
          field: "pollingTargetSystem",
          resizable: true,
          width: 150
        },
        {
          headerName: "Source System",
          field: "sourceSystem",
          resizable: true,
          width: 150
        },
        {
          headerName: "Successful",
          field: "successful",
          resizable: true,
          width: 150,
          cellRendererFramework: BoolCellRenderer
        },
        {
          headerName: "Items Posted",
          field: "totalItemsPosted",
          resizable: true,
          width: 150
        },
        {
          headerName: "Items Failed",
          field: "totalItemsFailed",
          resizable: true,
          width: 150
        }
      ]
    };
    this.apiClient = new GlasschainHttpClient();
    this.auth = new AuthService();

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleLogDetailOpen = this.handleLogDetailOpen.bind(this);
    this.handleLogDetailClose = this.handleLogDetailClose.bind(this);
  }

  async componentDidMount() {
    let searchParams = new TelemetrySearchParams();
    searchParams.maxCount = 300;
    var refreshedData = await this.apiClient.fetchTelemetryListBySearchParams(
      searchParams
    );
    console.group("Log Data: ");
    console.log(refreshedData);
    this.setState({ rowData: refreshedData });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();

    var rowData = this.state.rowData[selectedNodes[0].rowIndex];
    console.log("Selected Row Data");
    console.log(rowData);
    this.setState({
      currentRow: rowData
    });
  }

  handleShowDetail(){
    if (this.state.currentRow) {
      this.setState({syntaxDoc: JSON.stringify(this.state.currentRow, null, "  ")})
      this.handleLogDetailOpen();
    } else {
      alert("Select a Row!");
    }
  }

  handleLogDetailOpen(){
    this.setState({showLogDetail: true});
  }

  handleLogDetailClose(){
    this.setState({showLogDetail: false});
  }

  render() {


    const gridOptions = {
      rowSelection: "single",
      onRowDoubleClicked: this.handleShowDetail
    };


    return (
      <Grid>
        <Row>
          <Col>
            <div className="header-text">
              <h2>Logs</h2>
              <h4>Health Logs</h4>
              <hr />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
          <Modal show={this.state.showLogDetail} onHide={this.handleLogDetailClose}>
            <Modal.Header closeButton>
              <Modal.Title>Telemetry Log Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  <SyntaxHighlighter
                          id="Syntax"
                          showLineNumbers={false}
                          wrapLines={true}
                          style={docco}
                          children={this.state.syntaxDoc}
                        />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleLogDetailClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
        </Row>

        <Row>
          <Col>
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ marginBottom: "5px" }}></div>
          <div
            className="ag-theme-balham"
            style={{
              height: "500px",
              width: "1000px"
            }}
          >
            <AgGridReact
              rowSelection="multiple"
              columnDefs={this.state.columnDefs}
              enableRangeSelection={true}
              pagination={true}
              rowData={this.state.rowData}
              animateRows={true}
              //rowSelection={this.state.rowSelection}
              gridOptions={gridOptions}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
            />
          </div>
        </div>
        </Col>
        </Row>
      </Grid>
    );
  }
}
