// Import React FilePond

import React from "react";
import Card from "components/Card/Card.jsx";
import { Button, Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";

import Spinner from "react-spinkit";
import SweetAlert from "react-bootstrap-sweetalert";


import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";



import ReactJson from 'react-json-view';

import ShipmentExcelTemplate from "./gc-template-upload-shipment-v2.xlsx";
import GlasschainHttpClient from "../../components/ApiService/GlasschainHttpClient.js";



var emptyShipment = {};

export default class UploadDataPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [
      ],
      importCols: null,
      importRows: null,
      shipment: emptyShipment,
      alert: null,
      loading: false
    };
    this.apiClient = new GlasschainHttpClient();

    this.processFile = this.processFile.bind(this);
    this.toJsonShipment = this.toJsonShipment.bind(this);
    this.postShipment = this.postShipment.bind(this);
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }

  processFile(fileObj) {
    return;

  }



  async postShipment() {
    if (this.state.shipment != null) {
      this.setState({ loading: true });
      var result = await this.apiClient.postShipment(this.state.shipment);
    }
    this.setState({
      files: [],
      shipment: emptyShipment
    })
    this.setState({ loading: false });
    this.successAlert(); // move UP to really test. 
  }

  toJsonShipment(cols, rows) {
    var shipment = {};
    if (rows == null) return shipment;
    shipment.id = "0";
    shipment.ShipDateTime = rows[2][7];
    shipment.EtaDateTime = rows[2][8] || '';
    shipment.OrderNbr = rows[2][4];
    shipment.PoNbr = rows[2][5] || '';
    shipment.AsnNbr = rows[2][6] || '';
    shipment.SenderGlassbitId = rows[0][2];
    shipment.SenderGln = rows[0][3];
    shipment.CustomerNbr = rows[2][1];
    shipment.ReceiverGlassbitId = rows[2][0];
    shipment.CustomerGln = rows[2][3];
    shipment.CustomerName = rows[2][2];

    shipment.ShipFromInfo = {};
    shipment.ShipFromInfo.WarehouseNbr = rows[4][0];
    shipment.ShipFromInfo.WarehouseName = rows[4][1];
    shipment.ShipFromInfo.WarehouseGln = rows[4][2];
    shipment.ShipFromInfo.Address = {};
    shipment.ShipFromInfo.Address.StreetAddress = rows[4][3];
    shipment.ShipFromInfo.Address.Locality = rows[4][5];
    shipment.ShipFromInfo.Address.AdministrativeArea = rows[4][6];
    shipment.ShipFromInfo.Address.PostalCode = rows[4][7];
    //shipment.ShipFromInfo.Address.Iso2Country = 'US';
    shipment.ShipFromInfo.Address.Iso3Country = rows[4][8];

    shipment.ShipToInfo = {};
    shipment.ShipToInfo.CustomerNbr = rows[2][1];
    shipment.ShipToInfo.CustomerName = rows[2][2];
    shipment.ShipToInfo.WarehouseNbr = rows[6][0];
    shipment.ShipToInfo.WarehouseName = rows[6][1];
    shipment.ShipToInfo.WarehouseGln = rows[6][2];
    shipment.ShipToInfo.Address = {};
    shipment.ShipToInfo.Address.StreetAddress = rows[6][3];
    shipment.ShipToInfo.Address.Locality = rows[6][5];
    shipment.ShipToInfo.Address.AdministrativeArea = rows[6][6];
    shipment.ShipToInfo.Address.PostalCode = rows[6][7];
    shipment.ShipToInfo.Address.Iso2Country = '';
    shipment.ShipToInfo.Address.Iso3Country = rows[6][8];

    shipment.BillToInfo = {};
    shipment.BillToInfo.CustomerNbr = rows[8][0];
    shipment.BillToInfo.CustomerName = rows[8][1];
    shipment.BillToInfo.Duns = rows[8][2];
    shipment.BillToInfo.Address = {};
    shipment.BillToInfo.Address.StreetAddress = rows[8][3];
    shipment.BillToInfo.Address.Locality = rows[8][5];
    shipment.BillToInfo.Address.AdministrativeArea = rows[8][6];
    shipment.BillToInfo.Address.PostalCode = rows[8][7];
    shipment.BillToInfo.Address.Iso2Country = '';
    shipment.BillToInfo.Address.Iso3Country = rows[8][8];

    var shippedPallets = [];

    // how many pallets do we have? 
    let palletRows = []; // keep track of the row numbers of each pallet
    for (let i = 0; i < rows.length; i++) {
      if (rows[i][0] == 'PALLETNBR') {
        palletRows.push(i);
      }
    }

    for (let p = 0; p < palletRows.length; p++) {
      let pStartRow = palletRows[p];
      var shippedPallet = {};
      shippedPallet.PalletLicenseNbr = rows[pStartRow + 1][0]
      shippedPallet.PalletType = rows[pStartRow + 1][1];
      shippedPallet.Lines = [];

      let pLines = 0;
      let lStartLine = pStartRow + 3;
      if (p == palletRows.length - 1) {
        // this was the LAST pallet we're dealing with. That must mean the last row of lines is the last row of the data
        lStartLine = lStartLine - 1;
        pLines = rows.length - (lStartLine); // in this case the last line IS the last line of this row set. 
      }
      else {
        pLines = palletRows[p + 1] - lStartLine;
      }
      for (let l = 0; l < pLines; l++) {
        var shippedLine = {};
        shippedLine.LineNbr = rows[lStartLine + l][0];
        shippedLine.SoLineNbr = rows[lStartLine + l][1];
        shippedLine.Item = {};
        shippedLine.Item.ItemId = rows[lStartLine + l][2];
        shippedLine.Item.Description = rows[lStartLine + l][4];
        shippedLine.Item.Gtin = rows[lStartLine + l][5];
        shippedLine.Item.Uom = rows[lStartLine + l][6];
        shippedLine.LotQuantity = {};
        shippedLine.LotQuantity.LotDesignator = rows[lStartLine + l][9];
        shippedLine.LotQuantity.LotInfo = {};
        shippedLine.LotQuantity.LotInfo.LotDesignator = rows[lStartLine + l][10];
        shippedLine.LotQuantity.LotInfo.RanchNbr = rows[lStartLine + l][7];
        shippedLine.LotQuantity.LotInfo.RanchName = rows[lStartLine + l][8];
        shippedLine.LotQuantity.LotInfo.Item = {};
        shippedLine.LotQuantity.LotInfo.Item.ItemId = rows[lStartLine + l][2];
        shippedLine.LotQuantity.LotInfo.Item.Description = rows[lStartLine + l][4];
        shippedLine.LotQuantity.LotInfo.Item.Gtin = rows[lStartLine + l][5];
        shippedLine.LotQuantity.LotInfo.Item.Uom = rows[lStartLine + l][6];
        shippedLine.LotQuantity.LotInfo.Item.UomDescript = rows[lStartLine + l][6];
        shippedLine.LotQuantity.LotInfo.BlockDesignator = rows[lStartLine + l][9];
        shippedLine.LotQuantity.LotInfo.HarvestDate = rows[lStartLine + l][11];
        shippedLine.LotQuantity.Qty = rows[lStartLine + l][3];
        shippedPallet.Lines.push(shippedLine);
      }
      shippedPallets.push(shippedPallet);
    }

    shipment.ShippedPallets = shippedPallets;
    return shipment;
  }


  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Complete"
          onConfirm={() => {
            this.setState({
              alert: null
            });
            //this.props.history.push('/alertlist');
          }
          }
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
        >
          You've posted your shipment. Check out the dashboard for results of your post.
          Or upload another shipment from a file.
        </SweetAlert>
      )
    });
  }


  render() {

    return (


      <Grid>
        <Row>
          <Col md={12}>
            <div className="header-text">
              <h2>Data Upload Page</h2>
              <h4>Download an Excel Template and Upload the Results to Post a Shipment</h4>
              <hr />
            </div>
            <div>
              {this.state.alert}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={5} mdOffset={1}>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-cloud-download" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Step 1 - Download Template</Media.Heading>
                Start by downloading the simple Excel Template and fill it out.
                The template includes instructions.
            </Media.Body>
            </Media>
          </Col>
          <Col md={6}>
            <FormGroup>
              <p>
                <div align='center'>
                  <a href={ShipmentExcelTemplate}>Download Template</a>
                </div>
              </p>
            </FormGroup>

          </Col>
        </Row>

        <Row>
          <Col md={5} mdOffset={1}>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-cloud-upload" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Step 2 - Upload Filled Spreadsheet</Media.Heading>
                After you've completed filling out and saving your spreadsheet for
                a specific shipment, save the file to your hard drive and either
                drag the file into the upload area to the right or click the Browse
                button and select it from your folder.
            </Media.Body>
            </Media>

          </Col>
          <Col md={6}>
            <FormGroup>
              <p>
                <div className="App">
                  <FilePond
                    ref={ref => (this.pond = ref)}
                    labelIdle='Drag & Drop your completed Excel template files or <span class="filepond--label-action">Browse</span>'
                    files={this.state.files}
                    allowMultiple={false}
                    maxFiles={1}
                    oninit={() => this.handleInit()}
                    onupdatefiles={fileItems => {
                      // Set currently active file objects to this.state
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                      });
                      this.processFile(this.state.files[0]);

                    }}
                  />
                </div>
              </p>
            </FormGroup>

          </Col>
        </Row>


        <Row>
          <Col md={5} mdOffset={1}>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-glasses" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Step 3 - Review Your Shipment Data</Media.Heading>
                After you upload your spreadsheet, the system will translate it
                into the proper form for posting and display it for you on the
                left. Review and make sure it looks correct. If not, then update
                the spreadsheet and return to Step 2 - uploading your revised file.
            </Media.Body>
            </Media>
          </Col>
          <Col md={6}>
            <FormGroup>
              <p>
                <div>
                  <ReactJson
                    src={this.state.shipment}
                    name='Shipment'
                    theme='embers'
                    indentwidth={2}
                    displayObjectSize={false}
                    displayDataTypes={false}

                  />
                </div>
              </p>
            </FormGroup>
          </Col>
        </Row>



        <Row>
          <Col md={5} mdOffset={1}>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="pe-7s-upload" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Step 4 - Post it!</Media.Heading>
                If everything looks okay, then go ahead and hit the
                Post button on the right. The system will send your
                shipment and let you know when it's done. If there
                were any errors in posting, then they will be displayed
                to the right. Note that posting can take a minute or
                so. A loading icon will appear above the button you pushed
                and will go away when posting is complete. 
            </Media.Body>
            </Media>
          </Col>
          <Col md={6}>
            <FormGroup>
              <p>
                <div align='center'>
                <Spinner name="three-bounce"/>
                </div>
                <div align='center'>
                  <Button
                    onClick={this.postShipment}
                  > Post Me!
                        </Button>
                </div>
              </p>
            </FormGroup>

          </Col>
        </Row>
      </Grid >

    );


  }
}