import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';



const row1_columns = [
    { dataField: 'orderNbr', text: 'Order' },
    { dataField: 'poNbr', text: 'PO' },
    { dataField: 'shipTo', text: "ShipTo" }
]

const row2_columns = [
    { dataField: 'asnNbr', text: 'ASN' },
    { dataField: 'shipDateTime', text: 'Shipped', formatter: dtFormatter},
    { dataField: 'etaDateTime', text: 'ETA', formatter: dtFormatter }
]
const row3_columns = [
    {dataField: 'resultMsg', text: 'Results'}
]
const row4_columns = [
    { dataField: 'isRepostTransaction', text: 'Repost'},
    { dataField: 'repostMessage', text: "Repost Info" }
]

function dtFormatter(cell, row) {
    if (cell == null){ return <span></span>};
    var cellVal = cell.slice(0, -9).replace('T', ' '); // remove UTC offset and seconds
    return (
      <span> { cellVal }</span>
    );
  }





export default class OrderDetailSummary extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        // have to convert this to an array for the table component to work. Stupid - should find a different UI component
        const data = [
            this.props.orderDetail
        ]
        return (
            <div>
                <div>
                    <BootstrapTable keyField="id" data={data} columns={row1_columns} />
                </div>
                <div>
                    <BootstrapTable keyField="id" data={data} columns={row2_columns} />
                </div>
                <div>
                    <BootstrapTable keyField="id" data={data} columns={row3_columns} />
                </div>
                <div>
                    <BootstrapTable keyField="id" data={data} columns={row4_columns} />
                </div>
            </div>

        );
    }
}
