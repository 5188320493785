import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";

const post_data_columns = [
    { dataField: 'retailerGcId', text: "Retailer", formatter: imageFormatter },
    { dataField: 'correlationId', text: 'ID', hidden:true },
    { dataField: 'docMasterNbr', text: "Order" },
    { dataField: 'eventDateTimeLocal', text: "Posted", formatter: dtFormatter},
    { dataField: 'status', text: "Result" },
    {dataField: 'FtPostEnviron', text: 'Environ'}
]

function imageFormatter(cell, row) {
    return (
        <img src={require(`../assets/img/logos/${cell}-flag.png`)}  alt={cell} />
    );
}

function dtFormatter(cell, row) {
  var cellVal = cell.slice(0, -9); // remove UTC offset and seconds
  return (
    <span> { cellVal }</span>
  );
}

export default class DocumentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            currPage: 1
        };
        this.dataClient = new GlasschainHttpClient();
 
    }
 


    render() {

      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#bbecee'
      };


        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                //alert(`double clicked on row with key: ${post_data[rowIndex].ordernbr}`);
                console.log(`double clicked on row with index: ${rowIndex}`);
                //console.log(`clicked on row with index: ${rowIndex}`);
            },
            onClick: (e, row, rowIndex) => {
              //alert(`clicked on row with key: ${post_data[rowIndex].ordernbr}`);
              console.log(`clicked on row with index: ${rowIndex}`);
              //console.log(`clicked on row with index: ${rowIndex}`);
              //var listRow = this.props.summaryList[rowIndex];
              //var data = listRow.orderSummary;
              // find the right row. We can't use the index because with pagination, this will vary
              var orderNbr = row.docMasterNbr; // don't use docMasterNbr! That will return just the order, not necessarily the correct transaction!
              var correlationId = row.correlationId;
              var result = this.props.summaryList.find(obj => {
                return obj.correlationId === correlationId
              });
              var data = result.orderSummary;
              this.props.onOrderDetailChange(data); // lifts state up to dashboard
            },
            onMouseEnter: (e, row, rowIndex) => {
              console.log(`enter on row with index: ${rowIndex}`);   
            }
          };
        
          const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
          }) => (
            <div className="btn-group" role="group">
              {
                options.map((option) => {
                  const isSelect = currSizePerPage === `${option.page}`;
                  return (
                    <button
                      key={ option.text }
                      type="button"
                      onClick={ () => onSizePerPageChange(option.page) }
                      className={ `btn ${isSelect ? 'btn-secondary' : 'btn-warning'}` }
                    >
                      { option.text }
                    </button>
                  );
                })
              }
            </div>
          );

          const options = {
            sizePerPageRenderer,
            sizePerPageList: [5,10,15,20] 
          };


        
        const docTable = (
            <BootstrapTable
                keyField="correlationId"
                data={this.props.summaryList}
                columns={post_data_columns}
                rowEvents={rowEvents}
                striped
                hover
                condensed
                selectRow={ selectRow }
                pagination={ paginationFactory(options) }
            
            />

        );


        return (
            <Card
                title=""
                category=""
                ctFullWidth
                content={docTable}
            />
        );
    }


}