
import React, { Component } from "react";

export default class SearchCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctFullWidth ? " content-full-width" : "") +
            (this.props.ctTextCenter ? " text-center" : "") +
            (this.props.tableFullWidth ? " table-full-width" : "")
          }
        >
          {this.props.content}
        </div>
        <div className="footer">
          <hr />
          <div className="stats">
            {this.props.statsIcon} {this.props.statsIconText}
            {this.props.searchErrorLabel}
          </div>
        </div>
      </div>
    );
  }
}

