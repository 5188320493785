// Import React FilePond

import React from "react";
import {Button, Modal, Grid, Row, Col, Panel, PanelGroup} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import AuthService from "components/AuthService/AuthService.js";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

// use these as initial defaults
import { registered_entity_data } from "variables/Variables.jsx";

import GlasschainHttpClient from "../../components/ApiService/GlasschainHttpClient.js";
import DateTimeCellRenderer from "../../components/AgGridRenderers/DateTimeCellRenderer.jsx";

function parseRetailerId(fullKey){
  return fullKey.split('-')[1];
}

function parseKeyValue(fullKey){
  return fullKey.split('::')[2];
}

export default class MasterDataPage extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      currentRow: null,
      showRegisteredEntityDetail: false,
      syntaxDoc: null,
      warehouseList: registered_entity_data,
      ranchList: registered_entity_data,
      itemList: registered_entity_data,
      warehousePanelOpen: false,
      ranchPanelOpen: false,
      itemPanelOpen: false,
      columnDefs: [
        {
          headerName: "Customer ID",
          field: "gcid",
          resizable: true,
          width: 150
        },
        {
          headerName: "Retailer ID",
          field: "retailer",
          resizable: true,
          width: 150,
          sortable: true,
          filter: true
        },
        {
          headerName: "Entity Type",
          field: "entityType",
          resizable: true,
          width: 150,
          sortable: true,
          filter: true
        },
        {
          headerName: "Entity ID",
          field: "entityId",
          resizable: true,
          width: 150,
          sortable: true,
          filter: true
        },
        {
          headerName: "Last Registered",
          field: "lastRegistered",
          resizable: true,
          width: 150,
          sortable: true,
          filter: true,
          cellRendererFramework: DateTimeCellRenderer
        }
      ]
    };
    this.apiClient = new GlasschainHttpClient();
    this.auth = new AuthService();

    this.onWarehouseSelectionChanged = this.onWarehouseSelectionChanged.bind(this);
    this.onRanchSelectionChanged = this.onRanchSelectionChanged.bind(this);
    this.onItemSelectionChanged = this.onItemSelectionChanged.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleDetailOpen = this.handleDetailOpen.bind(this);
    this.handleDetailClose = this.handleDetailClose.bind(this);
  }

  async componentDidMount() {
    var refreshedData = await this.apiClient.fetchRegisteredEntityList();

    var warehouses = refreshedData
    .filter(row => row.entityType=='dc')
    .map((row, index) => {
      return {gcid: row.gcid, retailer: parseRetailerId(row.key), entityType: row.entityType, lastRegistered: row.lastRegistered, entityId: parseKeyValue(row.key) }
    });
    this.setState({ warehouseList: warehouses});

    var ranches = refreshedData
    .filter(row => row.entityType=='ranch')
    .map((row, index) => {
      return {gcid: row.gcid, retailer: parseRetailerId(row.key), entityType: row.entityType, lastRegistered: row.lastRegistered, entityId: parseKeyValue(row.key) }
    });
    this.setState({ ranchList: ranches});

    var items = refreshedData
    .filter(row => row.entityType=='item')
    .map((row, index) => {
      return {gcid: row.gcid, retailer: parseRetailerId(row.key), entityType: row.entityType, lastRegistered: row.lastRegistered, entityId: parseKeyValue(row.key) }
    });
    this.setState({ itemList: items});
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onWarehouseSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var warehouseList = this.state.warehouseList[selectedNodes[0].rowIndex];
    console.log("Selected Row Data");
    console.log(warehouseList);
    this.setState({
      currentRow: warehouseList
    });
  }

  onRanchSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var ranchList = this.state.ranchList[selectedNodes[0].rowIndex];
    console.log("Selected Row Data");
    console.log(ranchList);
    this.setState({
      currentRow: ranchList
    });
  }

  onItemSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var itemList = this.state.itemList[selectedNodes[0].rowIndex];
    console.log("Selected Row Data");
    console.log(itemList);
    this.setState({
      currentRow: itemList
    });
  }

  handleShowDetail(){
     if (this.state.currentRow) {
      this.setState({syntaxDoc: JSON.stringify(this.state.currentRow, null, "  ")})
      this.handleDetailOpen();
    } else {
      alert("Select a Row!");
    }
  }

  handleDetailOpen(){
    this.setState({showRegisteredEntityDetail: true});
  }

  handleDetailClose(){
    this.setState({showRegisteredEntityDetail: false});
  }

  render() {
    const gridOptions = {
      rowSelection: "single",
      onRowDoubleClicked: this.handleShowDetail
    };

    return (
      <div className="main-content">
        <Row>
            <Col>
              <div className="header-text">
                <h4>Master Data</h4>
                <hr />
              </div>
            </Col>
        </Row>
        <PanelGroup id="panels" ref="panels">
          <Panel eventKey="1" expanded={this.state.warehousePanelOpen}>
                <Panel.Heading
                  onClick={() =>
                    this.setState({ warehousePanelOpen: !this.state.warehousePanelOpen })
                  }
                >
                  <Panel.Title toggle>
                    &nbsp;&nbsp;Warehouse
                    <b className="caret" />
                  </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <Grid>
                  <Row>
                    <Col>
                    <Modal show={this.state.showRegisteredEntityDetail} onHide={this.handleDetailClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Warehouse Detail</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                            <SyntaxHighlighter
                                    id="Syntax"
                                    showLineNumbers={false}
                                    wrapLines={true}
                                    style={docco}
                                    children={this.state.syntaxDoc}
                                  />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDetailClose}>
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ marginBottom: "5px" }}></div>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: "500px",
                            width: "1000px"
                          }}
                        >
                          <AgGridReact
                            rowSelection="multiple"
                            columnDefs={this.state.columnDefs}
                            enableRangeSelection={true}
                            pagination={true}
                            rowData={this.state.warehouseList}
                            animateRows={true}
                            gridOptions={gridOptions}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onWarehouseSelectionChanged.bind(this)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel.Body>
          </Panel>
          <Panel eventKey="2" expanded={this.state.ranchPanelOpen}>
                <Panel.Heading
                  onClick={() =>
                    this.setState({ ranchPanelOpen: !this.state.ranchPanelOpen })
                  }
                >
                  <Panel.Title toggle>
                    &nbsp;&nbsp;Ranch
                    <b className="caret" />
                  </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <Grid>
                  <Row>
                    <Col>
                    <Modal show={this.state.showRegisteredEntityDetail} onHide={this.handleDetailClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Ranch Detail</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                            <SyntaxHighlighter
                                    id="Syntax"
                                    showLineNumbers={false}
                                    wrapLines={true}
                                    style={docco}
                                    children={this.state.syntaxDoc}
                                  />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDetailClose}>
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ marginBottom: "5px" }}></div>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: "500px",
                            width: "1000px"
                          }}
                        >
                          <AgGridReact
                            rowSelection="multiple"
                            columnDefs={this.state.columnDefs}
                            enableRangeSelection={true}
                            pagination={true}
                            rowData={this.state.ranchList}
                            animateRows={true}
                            gridOptions={gridOptions}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onRanchSelectionChanged.bind(this)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel.Body>
            </Panel>
            <Panel eventKey="3" expanded={this.state.itemPanelOpen}>
                <Panel.Heading
                  onClick={() =>
                    this.setState({ itemPanelOpen: !this.state.itemPanelOpen })
                  }
                >
                  <Panel.Title toggle>
                    &nbsp;&nbsp;Item
                    <b className="caret" />
                  </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <Grid>
                  <Row>
                    <Col>
                    <Modal show={this.state.showRegisteredEntityDetail} onHide={this.handleDetailClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Item Detail</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                            <SyntaxHighlighter
                                    id="Syntax"
                                    showLineNumbers={false}
                                    wrapLines={true}
                                    style={docco}
                                    children={this.state.syntaxDoc}
                                  />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDetailClose}>
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ marginBottom: "5px" }}></div>
                        <div
                          className="ag-theme-balham"
                          style={{
                            height: "500px",
                            width: "1000px"
                          }}
                        >
                          <AgGridReact
                            rowSelection="multiple"
                            columnDefs={this.state.columnDefs}
                            enableRangeSelection={true}
                            pagination={true}
                            rowData={this.state.itemList}
                            animateRows={true}
                            gridOptions={gridOptions}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onItemSelectionChanged.bind(this)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Panel.Body>
            </Panel>
        </PanelGroup>
      </div>
    );
  }
}
