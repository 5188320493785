

export default class RetailerList{
    constructor(){
        this.list = [];
    }

    addRetailer(retailerPartner){
        this.list.push(retailerPartner);
    }


    toDropList(addAll = true){
        var result = [];
        for(let i=0; i<this.list.length; i++){
            result.push({value: this.list[i].gcid, label: this.list[i].name})
        }
        if (addAll){
            result.push({value: "all", label: "All"});
        }
        return result;
    }

    isEmpty(){
        return (this.list.length<1);
    }

}