/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
// react component that creates a dropdown menu for selection
import Select from "react-select";
import AuthService from '../../../components/AuthService/AuthService.js';
import GlasschainHttpClient from '../../../components/ApiService/GlasschainHttpClient.js';
import RetailerList from '../../../Models/RetailerList';
import { alertEventOptions} from "variables/Variables.jsx";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOrderNbr:  null,
      alertStartDate: null,
      alertEndDate: null,
      alertDateError: null,
      retailerList: [{value:'all', label:'All'}]
    }
    this.auth = new AuthService();
    this.apiClient = new GlasschainHttpClient();
  }

  async componentDidMount(){
    // tbd pass as a prop. I can't figure out how to do this with a routeed page. 
    let partnerships = await this.apiClient.fetchPartnerInfo();
    let retailerDropList = [];
    for (var i=0; i<partnerships.length; i++){
      var gcid = partnerships[i].participant.glasschaiN_IDENTIFIER;
      var nm = partnerships[i].participant.partY_ROLE_NAME;
      retailerDropList.push({value: gcid, label: nm});
    }
    retailerDropList.push({value: 'all', label: 'All'});
    this.setState({retailerList: retailerDropList});
  }

  handleAlertEventChange(selectedOption) {
    this.setState({ alertEvent: selectedOption.value });
    console.log(`Option selected:`, selectedOption);
  }

  handleAlertRetailerChange(selectedOption) {
    this.setState({ alertRetailer: selectedOption.value });
    console.log(`Option selected:`, selectedOption);
  }

  isValidated() {
    // tbd
    this.auth.setKey('subwiz-alertEvent', this.state.alertEvent);
    this.auth.setKey('subwiz-alertRetailer', this.state.alertRetailer);
    this.auth.setKey('subwiz-alertOrderNbr', this.state.alertOrderNbr);
    this.auth.setKey('subwiz-alertStartDate', this.state.alertStartDate);
    this.auth.setKey('subwiz-alertEndDate', this.state.alertEndDate);
    return true;
  }

  render() {

    //const retailerDropList = this.state.retailerList.toDropList();

    return (
      <div className="wizard-step">
        <h5 className="text-center">
          Tell us what you'd like to subscribe to.
        </h5>
        <Row>
        <Col md={5}>
            <FormGroup>
              <ControlLabel>
                Event<span className="text-danger">*</span>
              </ControlLabel>
              <Select
                name="alertEvent"
                options={alertEventOptions}
                placeholder="- event type -"
                onChange = {this.handleAlertEventChange.bind(this)}
              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <ControlLabel>
                Retailers <span className="text-danger">*</span>
              </ControlLabel>
              <Select
                name="alertRetailer"
                options={this.state.retailerList}
                placeholder="- retailer -"
                onChange = {this.handleAlertRetailerChange.bind(this)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={10}>
            <FormGroup>
              <ControlLabel>Order Number</ControlLabel>
              <FormControl type="text" name="alertOrderNbr" 
                placeholder="for a specific order number or blank for all" 
                onChange={event => this.setState({ alertOrderNbr: event.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>


        <Row>
          <Col md={5}>
            <FormGroup>
              <ControlLabel>From</ControlLabel>
              <FormControl type="date" name="From" 
                placeholder="ex: 2017-08 leave blank for all"
                value = {this.state.alertStartDate}
                onChange={event => this.setState({ alertStartDate: event.target.value })}

              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <ControlLabel>To</ControlLabel>
              <FormControl type="date" name="To" placeholder="ex: 2017-12-31 leave blank for all" 
              value = {this.state.alertEndDate}
               onChange={event => this.setState({ alertEndDate: event.target.value })}
              />

            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Step2;
