import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import AuthService from 'components/AuthService/AuthService.js';
import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";

export default class AlertSubscriptionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: "Id", field: "id", hide: true },
                { headerName: "Transport", field: "transportType", resizable: true, width:150 },
                { headerName: "Method", field: "transportAddress",resizable: true, width:150, sortable: true, filter: true },
                { headerName: "Event", field: "event", resizable: true, width:150  },
                { headerName: "FilterType", field: "identifierType", resizable: true, width:150  },
                { headerName: "Filter", field: "identifierValue", resizable: true, width:150  },
                { headerName: "Start", field: "startDate", resizable: true, width:150  },
                { headerName: "End", field: "endDate", resizable: true, width:150  }
            ]
        }
        this.apiClient = new GlasschainHttpClient();
        this.auth = new AuthService();
    }

    async componentDidMount() {
        var refreshedData = await this.apiClient.fetchSubscriptionAlerts();
        console.log(refreshedData);
        this.setState({ rowData: refreshedData.data });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    async onRemoveSelected() {
        var selectedData = this.gridApi.getSelectedRows();
        for(let i=0; i<selectedData.length; i++){
            let result = await this.apiClient.removeSubscriptionAlert(selectedData[i].id)
        }
        var res = this.gridApi.updateRowData({ remove: selectedData });
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ marginBottom: "5px" }}>
                    <div>
                        <button onClick={this.onRemoveSelected.bind(this)}>Remove Selected</button>
                    </div>
                </div>
                <div
                    className="ag-theme-balham"
                    style={{
                        height: '500px',
                        width: '1000px'
                    }}
                >
                    <AgGridReact
                        rowSelection="multiple"
                        columnDefs={this.state.columnDefs}
                        enableRangeSelection={true}
                        pagination={true}
                        rowData={this.state.rowData}
                        animateRows={true}
                        //rowSelection={this.state.rowSelection}
                        onGridReady={this.onGridReady}
                        
                    />
                </div>
            </div>
        );
    }
}