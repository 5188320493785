

export default class BatchSearchParams{
    constructor(params){
        this.maxCount = "";
        this.orderNbr = "";
        this.poNbr = "";
        this.correlationId = "";
        this.itemNbr =  "";
        this.gtin =  "";
        this.ranchNbr =  "";
        this.blockId = "";
        this.shipDateStart = ""; // in iso date format e.g. 2019-07-30
        this.shipDateEnd = "";
        this.harvestDateStart =  ""; // in iso format e.g. 2019-07-30
        this.harvestDateEnd = "";
        this.retailerGcId =  ""; // retailers pulled from partners query
        this.status =  ""; // ok or error or all

        this.queryString = "";

        this.toQueryString = this.toQueryString.bind(this);
        this.addToQueryString = this.addToQueryString.bind(this);
    }

    addToQueryString(p, name){
        if (p=="") return;
        if ((p=="all") || (p=="ALL")) return;
        //if (p.toUpperCase()=="ALL")return;
        if (p == null) return;
        if (p!=""){
            let qPiece = name + "=" + p;
            if (this.queryString !=""){
                this.queryString = this.queryString + "&" + qPiece;
            }
            else {
                this.queryString = qPiece;
            }
        }
    }

    toQueryString(){
        // there are definitely better ways!
        this.addToQueryString(this.retailerGcId, "retailergcid");
        this.addToQueryString(this.maxCount, "maxcount");
        this.addToQueryString(this.orderNbr, "ordernbr");
        this.addToQueryString(this.poNbr, "ponbr");
        this.addToQueryString(this.correlationId, "correlationid");
        this.addToQueryString(this.itemNbr, "itemnbr");
        this.addToQueryString(this.gtin, "gtin");
        this.addToQueryString(this.ranchNbr, "ranchnbr");
        this.addToQueryString(this.blockId, "blockid");
        this.addToQueryString(this.shipDateStart, "startdate");
        this.addToQueryString(this.shipDateEnd, "enddate");
        this.addToQueryString(this.harvestDateStart, "harveststartdate");
        this.addToQueryString(this.harvestDateEnd, "harvestenddate");
        this.addToQueryString(this.status, "status");
        return this.queryString;
    }


    validate(){
        return true;
    }
}