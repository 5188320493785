import React, { Component } from "react";
import { Grid, Col, Row, Panel, PanelGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import AuthService from "components/AuthService/AuthService.js";

import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import SystemHealthCard from "components/Card/SystemHealthCard.jsx";

import OrderDetailCard from "components/Card/OrderDetailCard.jsx";
import DocumentTable from "components/DocumentTable.jsx";
import ShipmentSearchBox from "components/ShipmentSearchBox.jsx";

// use these as initial defaults
import { treeData, post_data, orderdetail_data } from "variables/Variables.jsx";

import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";
import RetailerList from "../Models/RetailerList.js";
import RetailerPartner from "../Models/RetailerPartner.js";

import ActivityChart from "components/Charts/ActivityChart.jsx";
import OutageChart from "components/Charts/OutageChart.jsx";
import PostStatusChart from "components/Charts/ActivityBarChart.jsx";
import PostDocumentTable from "components/PostDocumentTable.jsx";
import TelemetryHealthStatus from "Models/TelemetryHealthStatus.js";

function diffMinutes(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

function toLocalTzOffset() {
  let offsetMins = new Date().getTimezoneOffset();
  if (offsetMins == 0) {
    return "+00:00";
  }
  let resultSign = offsetMins > 0 ? "+" : "-";
  let absOffsetMins = Math.abs(offsetMins);
  let hours = parseInt(absOffsetMins / 60);
  let mins = parseInt(absOffsetMins % 60);
  let strHours = hours < 10 ? "0" + hours.toString() : hours.toString();
  let strMins = mins < 10 ? "0" + mins.toString() : mins.toString();
  return resultSign + strHours + ":" + strMins;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.auth = new AuthService();
    this.gcid = this.auth.getUser();
    this.gcidAdapterName = this.gcid + " adapter";
    this.state = {
      firstGlobalUpdated: false,
      retailerList: new RetailerList(),
      treeData: treeData,
      docSyntaxData: "",
      healthPanelOpen: true,
      searchPanelOpen: true,
      tracePanelOpen: false,

      orderSummaryList: post_data,
      orderSummaryListFetchStatus: "",
      orderDetail: orderdetail_data,
      syntaxDoc: "",

      lastHealthCheckDateTime: new Date(),
      coreAvailable: false,
      coreDbAvailable: false,
      adapterHealthStatus: new TelemetryHealthStatus(),
      dataHealthStatus: new TelemetryHealthStatus(),
      ibmftHealthStatus: new TelemetryHealthStatus(),
    };
    this.coreClient = new GlasschainHttpClient();

    this.clickContentRender = this.clickContentRender.bind(this);

    this.orderSummaryListUpdate = this.orderSummaryListUpdate.bind(this);
    this.orderDetailUpdate = this.orderDetailUpdate.bind(this);
    this.traceTreeNodeUpdate = this.traceTreeNodeUpdate.bind(this);
    this.onHealthPanelClick = this.onHealthPanelClick.bind(this);
    this.onHealthPanelWheel = this.onHealthPanelWheel.bind(this);
    this.updateHealthStatus = this.updateHealthStatus.bind(this);
  }

  async updateHealthStatus() {
    var coreAvail = await this.coreClient.pingCore();
    var coreDbAvail = false;
    var ibmFtAvail = false;
    var lastFetchedDateTime = new Date()
      .toISOString()
      .replace("Z", toLocalTzOffset()); //"2011-12-19T15:28:46.493Z" UTC format
    if (coreAvail) {
      // then we can check it.
      coreDbAvail = await this.coreClient.pingCoreDb();
      ibmFtAvail = await this.coreClient.pingIbmFt();
    }
    var lastCheckedMinutesAgo = diffMinutes(
      new Date(),
      this.state.lastHealthCheckDateTime
    );
    var dataHealthStatus = this.getDataHealthStatus(
      coreAvail,
      coreDbAvail,
      lastFetchedDateTime
    );
    var ibmFtHealthStatus = this.getIbmFtHealthStatus(
      coreAvail,
      ibmFtAvail,
      lastFetchedDateTime
    );
    var adapterHealthStatus = await this.getAdapterHealthStatus(
      coreAvail,
      coreDbAvail,
      lastFetchedDateTime
    );

    this.setState({
      coreAvailable: coreAvail,
      coreDbAvailable: coreDbAvail,
      adapterHealthStatus: adapterHealthStatus,
      dataHealthStatus: dataHealthStatus,
      ibmftHealthStatus: ibmFtHealthStatus,
      lastHealthCheckDateTime: new Date(),
    });
  }

  async getAdapterHealthStatus(coreAvail, coreDbAvail, lastFetchedDateTime) {
    if (!coreAvail) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "problem",
        lastFetchedDateTime,
        "Core is not accessible!"
      );
    }
    if (!coreDbAvail) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "problem",
        lastFetchedDateTime,
        "Core is accessible, but Core Database is not accessible!"
      );
    }
    var lastReceivedEvent = await this.coreClient.fetchTelemetryLastAdapterEvent();
    // TBD: minutes to failure should be kept in a config for this partner!
    // how many minutes has it been since we last got an adapter polling event?
    if (lastReceivedEvent == null) {
      // there were probably no records!
      return new TelemetryHealthStatus(
        false,
        "warning",
        "warning",
        lastFetchedDateTime,
        "No Adapter Telemetry Events found for your adapter. Is adapter logging turned on?"
      );
    }
    var lastAdapterPolledMinutesAgo = diffMinutes(
      new Date(),
      new Date(lastReceivedEvent.pollingEndDateTime)
    );
    if (lastAdapterPolledMinutesAgo > 180) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "problem",
        lastFetchedDateTime,
        "Last Adapter Telemetry Event was over " +
          lastAdapterPolledMinutesAgo +
          " minutes ago!"
      );
    }
    if (lastAdapterPolledMinutesAgo > 60) {
      return new TelemetryHealthStatus(
        false,
        "warning",
        "warning",
        lastFetchedDateTime,
        "Last Adapter Telemetry Event was " +
          lastAdapterPolledMinutesAgo +
          " minutes ago!"
      );
    }

    // how many minutes has it been since last got an unsuccessful adapter polling event that was SENT to the Core?
    // if LESS THAN an hour, then we are yellow.
    var lastReceivedAndPostFailEvent = await this.coreClient.fetchTelemetryLastUnsuccessfulEventWithCorePost();
    if (lastReceivedAndPostFailEvent) {
      var lastAdapterUnsuccessfulEventMinutesAgo = diffMinutes(
        new Date(),
        new Date(lastReceivedAndPostFailEvent.pollingEndDateTime)
      );
      if (lastAdapterUnsuccessfulEventMinutesAgo < 1 * 60) {
        return new TelemetryHealthStatus(
          false,
          "warning",
          "warning",
          lastFetchedDateTime,
          "Unsuccessful Adapter Telemetry Event posted to Core within the last hour (" +
            lastAdapterUnsuccessfulEventMinutesAgo +
            " minutes ago)"
        );
      }
    }

    // must be green!
    var lastReceivedAndPostedEvent = await this.coreClient.fetchTelemetryLastSuccessfulEventWithCorePost();
    var lastAdapterSuccessfulEventMinutesAgo = diffMinutes(
      new Date(),
      new Date(lastReceivedAndPostedEvent.pollingEndDateTime)
    );
    return new TelemetryHealthStatus(
      false,
      "success",
      "healthy",
      lastFetchedDateTime,
      "Last Successful Adapter Telemetry Event posted to Core was " +
        lastAdapterSuccessfulEventMinutesAgo +
        " minutes ago and no failed events within the last hour"
    );
  }

  getIbmFtHealthStatus(coreAvail, ibmFtAvail, lastFetchedDateTime) {
    if (!coreAvail || !ibmFtAvail) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "danger",
        lastFetchedDateTime,
        "Core is not accessible so IBM Food Trust is not available!"
      );
    }
    return new TelemetryHealthStatus(
      false,
      "success",
      "healthy",
      lastFetchedDateTime,
      "IBM Food Trust is available"
    );
  }

  getDataHealthStatus(coreAvail, coreDbAvail, lastFetchedDateTime) {
    if (!coreAvail) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "danger",
        lastFetchedDateTime,
        "Core is not accessible!"
      );
    }
    if (!coreDbAvail) {
      return new TelemetryHealthStatus(
        false,
        "danger",
        "danger",
        lastFetchedDateTime,
        "Core is accessible, but Core Database is not accessible!"
      );
    }
    return new TelemetryHealthStatus(
      false,
      "success",
      "healthy",
      lastFetchedDateTime,
      "Core and Core Database are accessible"
    );
  }

  async componentDidMount() {
    // TBD - we really should see if we can talk to the core and the core can talk to the db. If not, then
    // we're not going to be able to do much except set the health alerts.
    if (this.auth.loggedIn()) {
      await this.updateHealthStatus();
    }

    if (this.auth.loggedIn() && !this.state.firstGlobalUpdated) {
      // first time?
      var result = await this.coreClient.fetchLastPostedBatchTrace();
      // tbd - deal with no result
      var resultData = [];
      resultData.push(result.data);
      this.setState({
        orderSummaryList: resultData,
        orderDetail: resultData[0].orderSummary,
        firstGlobalUpdated: true,
      });
    }
    if (this.auth.loggedIn() && this.state.retailerList.isEmpty()) {
      let partnerships = await this.coreClient.fetchPartnerInfo();
      let retailers = new RetailerList();
      for (var i = 0; i < partnerships.length; i++) {
        var gcid = partnerships[i].participant.glasschaiN_IDENTIFIER;
        var nm = partnerships[i].participant.partY_ROLE_NAME;
        retailers.addRetailer(new RetailerPartner(gcid, nm));
      }
      this.setState({ retailerList: retailers });
    }
  }

  orderSummaryListUpdate(data) {
    this.setState({ orderSummaryList: data });
    // tbd: may want to invalidate other values here
  }

  async orderDetailUpdate(data) {
    // pass in correlationId so we get the correct order in case there is more than one docMasterNbr
    var traceTreeResponse = await this.coreClient.fetchTraceTree(
      data.correlationId
    );
    this.setState({
      orderDetail: data,
      treeData: traceTreeResponse.data,
    });
  }

  async traceTreeNodeUpdate(transactionId) {
    var syntaxDocResponse = await this.coreClient.fetchSyntaxDoc(transactionId);
    var tempdata = syntaxDocResponse.data;
    var docBody = tempdata.jsonBody;
    var x = JSON.parse(docBody);
    tempdata.jsonBody = x;
    var data = JSON.stringify(tempdata, null, "\t");
    let result = data;
    this.setState({
      syntaxDoc: result,
      healthPanelOpen: false,
      searchPanelOpen: false,
      tracePanelOpen: true,
    });
  }

  orderListFetchStatus(status) {
    this.setState({ orderSummaryListFetchStatus: status });
  }

  async clickContentRender(targetNode) {
    var check = targetNode.transactionId;
    var format = targetNode.docFormat;
  }

  onHealthPanelClick(ev) {
    const parent = ev.target.parentElement;
    let pName = parent.className.toString();
    const headingWasClicked =
      pName.includes("panel-title") || pName.includes("panel-heading");
    if (!headingWasClicked) return ev.stopPropagation();
    this.setState({ healthPanelOpen: !this.state.healthPanelOpen });
  }

  onHealthPanelWheel(ev) {
    const parent = ev.target.parentElement;
    let pName = parent.className.toString();
    const headingWasClicked =
      pName.includes("panel-title") || pName.includes("panel-heading");
    if (!headingWasClicked) return ev.stopPropagation();
  }

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <PanelGroup id="panels" ref="panels">
            <Panel
              eventKey="1"
              expanded={this.state.healthPanelOpen}
              onClick={this.onHealthPanelClick} //() => this.setState({ healthPanelOpen: !this.state.healthPanelOpen })}
              onWheel={this.onHealthPanelWheel}
            >
              <Panel.Heading>
                <Panel.Title toggle>
                  &nbsp;&nbsp;System Health
                  <b className="caret" />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <Row>
                  <Col lg={2} sm={4}>
                    <SystemHealthCard
                      // text-success
                      // text-warning
                      // text-danger
                      healthState={this.state.adapterHealthStatus.healthState}
                      bigIconText={
                        this.state.adapterHealthStatus.lastPolledMessage
                      }
                      statsText="ERP Adapter"
                      statsValue={this.state.adapterHealthStatus.healthText}
                      statsIconText={
                        this.state.adapterHealthStatus.lastPolledTime
                      }
                      lastHealthCheckDateTime={
                        this.state.lastHealthCheckDateTime
                      }
                    />
                  </Col>
                  <Col lg={2} sm={4}>
                    <SystemHealthCard
                      healthState={this.state.dataHealthStatus.healthState}
                      bigIconText={
                        this.state.dataHealthStatus.lastPolledMessage
                      }
                      statsText="Data"
                      statsValue={this.state.dataHealthStatus.healthText}
                      statsIconText={this.state.dataHealthStatus.lastPolledTime}
                      lastHealthCheckDateTime={
                        this.state.lastHealthCheckDateTime
                      }
                    />
                  </Col>
                  <Col lg={2} sm={4}>
                    <SystemHealthCard
                      healthState={this.state.ibmftHealthStatus.healthState}
                      bigIconText={
                        this.state.ibmftHealthStatus.lastPolledMessage
                      }
                      statsText="Food Trust"
                      statsValue={this.state.ibmftHealthStatus.healthText}
                      statsIconText={
                        this.state.ibmftHealthStatus.lastPolledTime
                      }
                      lastHealthCheckDateTime={
                        this.state.lastHealthCheckDateTime
                      }
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <PostStatusChart />
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>

            <Panel
              eventKey="2"
              expanded={this.state.searchPanelOpen}
              onWheel={this.onHealthPanelWheel}
            >
              <Panel.Heading
                onClick={() =>
                  this.setState({
                    searchPanelOpen: !this.state.searchPanelOpen,
                  })
                }
              >
                <Panel.Title toggle>
                  &nbsp;&nbsp;Search
                  <b className="caret" />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible expanded={true}>
                <Row>
                  <Col md={12}>
                    <Card
                      title=""
                      category=""
                      content={
                        <Row>
                          <Col md={2}>
                            <ShipmentSearchBox
                              onOrderSummaryListChange={
                                this.orderSummaryListUpdate
                              }
                              onOrderSummaryListFetchStatusChange={
                                this.orderListFetchStatus
                              }
                              retailerList={this.state.retailerList}
                            />
                          </Col>
                          <Col md={5}>
                            <PostDocumentTable
                              summaryList={this.state.orderSummaryList}
                              onOrderDetailChange={this.orderDetailUpdate}
                            />
                          </Col>
                          <Col md={5}>
                            <OrderDetailCard
                              orderDetail={this.state.orderDetail}
                              treeData={this.state.treeData}
                              onTraceTreeNodeUpdate={this.traceTreeNodeUpdate}
                            />
                          </Col>
                        </Row>
                      }
                    />
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>

            <Panel eventKey="3" expanded={this.state.tracePanelOpen}>
              <Panel.Heading
                onClick={() =>
                  this.setState({ tracePanelOpen: !this.state.tracePanelOpen })
                }
              >
                <Panel.Title toggle>
                  &nbsp;&nbsp;Document Inspection
                  <b className="caret" />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <Row>
                  <Col md={12}>
                    <Card
                      title="Document Detail"
                      category=""
                      content={
                        <SyntaxHighlighter
                          id="Syntax"
                          language="json"
                          showLineNumbers={true}
                          wrapLines={true}
                          style={docco}
                          children={this.state.syntaxDoc}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Dashboard);
