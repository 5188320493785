import React, { Component } from "react";
import { Button, Tab, Nav, NavItem, Col, Row, FormGroup, FormControl, ControlLabel }
    from "react-bootstrap";
// react component that creates a dropdown menu for selection
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import SearchCard from "components/Card/SearchCard.jsx";
import GlasschainHttpClient from "components/ApiService/GlasschainHttpClient.js";

import BatchSearchParam from '../Models/BatchSearchParams.js';


import SearchItemParams from "../Models/SearchItemParams.js";
import SearchTimeframeParams from "../Models/SearchTimeframeParams.js";

import { alertRetailerOptions } from '../variables/Variables.jsx';

export default class ShipmentSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            retailerList: [{ value: "all", label: "All" }],
            lastPostSearchLastXValue: "",
            lastPostSearchLastMinValue: "",
            lastPostRetailer: "all",
            lastPostStatus: "all",

            orderSearchOrderNbrValue: "",
            orderSearchPoNbrValue: "",

            itemSearchItemNbrValue: "",
            itemSearchGtinValue: "",
            itemSearchRanchNbrValue: "",
            itemSearchBlockIdValue: "",
            itemSearchHarvestStartDateValue: "",
            itemSearchHarvestEndDateValue: "",
            itemSearchShipStartDateValue: "",
            itemSearchShipEndDateValue: "",
            itemSearchRetailer: "all",
            itemSearchStatus: "all",

            refreshStatus: "",
            refreshStatusLabel: "",
            searchCriteriaError: false,
            searchCriteriaErrorLabel: null,
            searchResultHit: false,
            autoRefresh: true,
            inWaiting: "atRest",
            lastFetchedText: ""
        };
        this.searchClient = new GlasschainHttpClient();

        this.onStandardChange = this.onStandardChange.bind(this);

        this.handleLastSearchSubmit = this.handleLastSearchSubmit.bind(this);
        this.handleOrderSearchSubmit = this.handleOrderSearchSubmit.bind(this);
        this.handleItemSearchSubmit = this.handleItemSearchSubmit.bind(this);

    }


    async componentDidMount() {
        /*
        let partnerships = await this.searchClient.fetchPartnerInfo();
        let retailers = [];
        for (var i=0; i<partnerships.length; i++){
          var gcid = partnerships[i].participant.glasschaiN_IDENTIFIER;
          var nm = partnerships[i].participant.partY_ROLE_NAME;
          retailers.push({value: gcid, label: nm});
        }
        retailers.push({value: 'all', label: 'All'});
        this.setState({retailerList: retailers});
        */
    }

    onStandardChange = e => this.setState({ [e.target.name]: e.target.value })

    handleLastRetailerChange(selectedOption) {
        this.setState({ lastPostRetailer: selectedOption.value });
        console.log(`Option selected:`, selectedOption);
    }

    handleItemRetailerChange(selectedOption) {
        this.setState({ itemSearchRetailer: selectedOption.value });
        console.log(`Option selected:`, selectedOption);
    }

    handleItemStatusChange(selectedOption) {
        this.setState({ itemSearchStatus: selectedOption.value });
        console.log(`Option selected:`, selectedOption);
    }




    formatDate = (dt) => {
        const leadingZero = (num) => `0${num}`.slice(-2);
        return [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()]
            .map(leadingZero)
            .join('-');
    }

    formatTime = (dt) => {
        const leadingZero = (num) => `0${num}`.slice(-2);
        return [dt.getHours(), dt.getMinutes(), dt.getSeconds()]
            .map(leadingZero)
            .join(':');
    }

    getCurrentIsoDateTime() {
        var dt = new Date();
        let dateTime = this.formatDate(dt) + 'T' + this.formatTime(dt);
        return dateTime;
    }


    async handleLastSearchSubmit(event) {
        try {
            this.setState({
                inWaiting: "inWaiting",
                lastFetchedText: "Fetching..."
            });
            var response;
            if (this.state.lastPostSearchLastXValue != "") {
                response = await this.searchClient.fetchBatchByLastXCount(this.state.lastPostSearchLastXValue, this.state.lastPostRetailer);
            }
            else {
                response = await this.searchClient.fetchBatchByLastXMinutes(this.state.lastPostSearchLastMinValue, this.state.lastPostRetailer);
            }
            this.props.onOrderSummaryListChange(response.data); // lifts state up to dashboard
            this.setState({ searchCriteriaErrorLabel: null });
        }
        catch (err) {
            this.setState({
                searchCriteriaErrorLabel: err.toString(),
                searchCriteriaError: true
            });
            this.setState({
                searchCriteriaErrorLabel: (
                    <small className="text-danger">
                        Error fetching your search. [{err.toString}];
                        </small>
                )
            });
        }
        finally {
            let currentDateTime = this.getCurrentIsoDateTime();
            this.setState({
                inWaiting: "",
                lastFetchedText: " " + currentDateTime
            });
        }
    }

    async handleOrderSearchSubmit(event) {
        try {
            this.setState({
                inWaiting: "inWaiting",
                lastFetchedText: "Fetching..."
            });
            var search = new BatchSearchParam();
            // weird and stupid. isCorrelationId?
            if (this.state.orderSearchOrderNbrValue.length > 20) {
                search.correlationId = this.state.orderSearchOrderNbrValue;
            }
            else {
                search.orderNbr = this.state.orderSearchOrderNbrValue;
            }
            if (this.state.orderSearchPoNbrValue != "") search.poNbr = this.state.orderSearchPoNbrValue;

            //var response = await this.searchClient.fetchBatchByOrderNbr(this.state.orderSearchOrderNbrValue);
            var response = await this.searchClient.fetchBatchListBySearchParams(search);
            this.props.onOrderSummaryListChange(response.data); // lifts state up to dashboard
            this.setState({ searchCriteriaErrorLabel: null });
        }
        catch (err) {
            this.setState({
                searchCriteriaErrorLabel: err.toString(),
                searchCriteriaError: true
            });
            this.setState({
                searchCriteriaErrorLabel: (
                    <small className="text-danger">
                        Error fetching your search. [{err.toString}];
                        </small>
                )
            });
        }
        finally {
            let currentDateTime = this.getCurrentIsoDateTime();
            this.setState({
                inWaiting: "",
                lastFetchedText: " " + currentDateTime
            });
        }
    }

    async handleItemSearchSubmit(event) {
        try {
            this.setState({
                inWaiting: "inWaiting",
                lastFetchedText: "Fetching..."
            });
            var search = new BatchSearchParam();
            if (this.state.itemSearchItemNbrValue != "") search.itemNbr = this.state.itemSearchItemNbrValue;
            if (this.state.itemSearchGtinValue != "") search.gtin = this.state.itemSearchGtinValue;
            if (this.state.itemSearchRanchNbrValue != "") search.ranchNbr = this.state.itemSearchRanchNbrValue;
            if (this.state.itemSearchBlockIdValue != "") search.blockId = this.state.itemSearchBlockIdValue;
            if (this.state.itemSearchRetailer != "") search.retailerGcId = this.state.itemSearchRetailer;
            if (this.state.itemSearchStatus != "") search.status = this.state.itemSearchStatus;
            if (this.state.itemSearchHarvestStartDateValue != "") search.harvestDateStart = this.state.itemSearchHarvestStartDateValue;
            if (this.state.itemSearchHarvestEndDateValue != "") search.harvestDateEnd = this.state.itemSearchHarvestEndDateValue;
            if (this.state.itemSearchShipStartDateValue != "") search.shipDateStart = this.state.itemSearchShipStartDateValue;
            if (this.state.itemSearchShipEndDateValue != "") search.shipDateEnd = this.state.itemSearchShipEndDateValue;

            var response = await this.searchClient.fetchBatchListBySearchParams(search);
            this.props.onOrderSummaryListChange(response.data); // lifts state up to dashboard
            this.setState({ searchCriteriaErrorLabel: null });
        }
        catch (err) {
            this.setState({
                searchCriteriaErrorLabel: err.toString(),
                searchCriteriaError: true
            });
            this.setState({
                searchCriteriaErrorLabel: (
                    <small className="text-danger">
                        Error fetching your search. [{err.toString}];
                        </small>
                )
            });
        }
        finally {
            let currentDateTime = this.getCurrentIsoDateTime();
            this.setState({
                inWaiting: "",
                lastFetchedText: " " + currentDateTime
            });
        }
    }




    render() {

        const retailerDropList = this.props.retailerList.toDropList();

        const statusDropList = [
            { value: "ok", label: "Ok" },
            { value: "error", label: "Error" },
            { value: "all", label: "All" }
        ]

        const tabsIcons = (
            <div>
                <Tab.Container id="tabs-with-dropdown" defaultActiveKey="info">
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Nav bsStyle="tabs">
                                <NavItem eventKey="last">
                                    <i className="fa fa-clock-o" /> Last
                            </NavItem>
                                <NavItem eventKey="order">
                                    <i className="fa fa-reorder" /> Order
                            </NavItem>
                                <NavItem eventKey="item">
                                    <i className="fa fa-opencart" /> Item
                            </NavItem>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content animation>
                                <Tab.Pane eventKey="last">
                                    <FormGroup>
                                        <ControlLabel>Last X Shipments</ControlLabel>
                                        <FormControl type="number" name="lastPostSearchLastXValue" placeholder="ex: 10" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>
                                            for Retailer<span className="text-danger">*</span>
                                        </ControlLabel>
                                        <Select
                                            name="retailerSelect"
                                            value={this.state.lastRetailer}
                                            options={retailerDropList}
                                            palceholder="- retailer -"
                                            onChange={this.handleLastRetailerChange.bind(this)}
                                        />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <Button
                                        bsStyle="info"
                                        fill
                                        onClick={this.handleLastSearchSubmit}
                                    >
                                        Search
                                    </Button>






                                </Tab.Pane>
                                <Tab.Pane eventKey="order">
                                    <FormGroup>
                                        <ControlLabel>Order Nbr</ControlLabel>
                                        <FormControl type="text" name="orderSearchOrderNbrValue" placeholder="ex: 10324" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>PO</ControlLabel>
                                        <FormControl type="text" name="orderSearchPoNbrValue" placeholder="ex: 10" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <Button
                                        bsStyle="info"
                                        fill
                                        onClick={this.handleOrderSearchSubmit}
                                    >
                                        Search
                                    </Button>
                                </Tab.Pane>
                                <Tab.Pane eventKey="item">
                                    <FormGroup>
                                        <ControlLabel>Item Nbr</ControlLabel>
                                        <FormControl type="text" name="itemSearchItemNbrValue" placeholder="ex: 10324" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>GTIN</ControlLabel>
                                        <FormControl type="text" name="itemSearchGtinValue" placeholder="ex: 10324" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>Ranch Nbr</ControlLabel>
                                        <FormControl type="text" name="itemSearchRanchNbrValue" placeholder="ex: 10324" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>Block Id</ControlLabel>
                                        <FormControl type="text" name="itemSearchBlockIdValue" placeholder="ex: A11F32" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>Post Status</ControlLabel>
                                        <Select
                                            name="itemStatusSelect"
                                            value={this.state.itemStatus}
                                            options={statusDropList}
                                            palceholder="- status -"
                                            onChange={this.handleItemStatusChange.bind(this)}
                                        />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>for Retailer</ControlLabel>
                                        <Select
                                            name="itemRetailerSelect"
                                            value={this.state.itemRetailer}
                                            options={retailerDropList}
                                            palceholder="- retailer -"
                                            onChange={this.handleItemRetailerChange.bind(this)}
                                        />
                                    </FormGroup>
                                    <div className="clearfix" />
                                    <FormGroup>
                                        <ControlLabel>Harvest Date</ControlLabel>
                                        <FormControl type="date" name="itemSearchHarvestStartDateValue" disabled placeholder="ex: 2019-09-01" onChange={this.onStandardChange} />
                                        <FormControl type="date" name="itemSearchHarvestEndDateValue" disabled placeholder="ex: 2019-09-03" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Ship Date</ControlLabel>
                                        <FormControl type="date" name="itemSearchShipStartDateValue" placeholder="ex: 2019-09-03" onChange={this.onStandardChange} />
                                        <FormControl type="date" name="itemSearchShipEndDateValue" placeholder="ex: 2019-09-07" onChange={this.onStandardChange} />
                                    </FormGroup>
                                    <Button
                                        bsStyle="info"
                                        fill
                                        onClick={this.handleItemSearchSubmit}
                                    >
                                        Search
                                        </Button>
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );



        return (

            <SearchCard
                title=""
                category=""
                content={
                    tabsIcons
                }
                statsIcon={<i className="fa fa-refresh" id={this.state.inWaiting} />}
                statsIconText={this.state.lastFetchedText}
                searchErrorLabel={this.state.searchCriteriaErrorLabel}

            />
        );
    }
}



