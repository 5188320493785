import React, { Component } from "react";

export default class ZipCellRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    formatFlag(value) {
        return <span>
            <img
                src={require(`../../assets/img/zip-ico.png`)}
                alt={value}
                onClick={this.invokeParentMethod}

            />
        </span>

    }

    invokeParentMethod() {
        this.props.context.componentParent.methodFromParent(this.props.node.rowIndex);
    }

    render() {
        return (
            <span>{this.formatFlag(this.props.value)}</span>
        );
    }
}






/*
export default class ZipCellRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //value: props.value
        }
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        this.props.context.componentParent.methodFromParent(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`)
    }

    formatFlag(value) {
        return <span><img src={require(`../../assets/img/zip-ico.png`)} alt={value}/></span>

    }

    refresh(){
        return false; // tell underlying grid to reconstruct
    }

    render() {
        return (
            <span><button style={{height: 20, lineHeight: 0.5}} onClick={this.invokeParentMethod} className="btn btn-info">zip</button></span>
        );


        /*
        return (
            <span>{this.formatFlag(this.props.value)}onClick={this.invokeParentMethod}</span>
        );
        */
