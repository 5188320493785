
import React, { Component } from "react";
import { ButtonGroup, Pagination, Grid, Row, Col } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

class Trace extends Component {
  render() {
    return (
      <div className="main-content buttons-with-margin">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="card">
                <Row>
                  <Col md={6}>
                    <div className="header">
                      <h4 className="title">Trace - Coming Soon</h4>
                    </div>
                </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Trace;
