
import React, { Component } from "react";
// react component that creates a form divided into multiple steps
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import AlertSubscriptionsTable from '../../components/AlertSubscriptionsTable.jsx';


export default class SubscriptionPage extends Component {
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={14} mdOffset={2}>
              <Card
                wizard
                id="subscriptionCard"
                textCenter
                title="Alert Subscriptions"
                category=""
                content={
                  <AlertSubscriptionsTable/>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

