
import React from "react";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import AuthService from '../../../components/AuthService/AuthService.js';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      emailError: null,
      text: "",
      textError: null
    };
    this.auth = new AuthService();
  }


  isValidated() {
    var re = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;
    let x = re.test(this.state.text);
    if (!x){
      this.setState({
        textError: (
            <small className="text-danger">
              Phone Number is required and format should be <i>+[CountryCode]</i>
            </small>
        )
      })
      return false;
    }
    else {
      this.setState({textError: null});
      // set storage
      this.auth.setKey('subwiz-text', this.state.text);
      return true;
    }
  }





  


  render() {
    return (
      <div className="wizard-step">
        <h5 className="text-center">How do you want to be notified?</h5>
        <Row>
          <Col md={10} mdOffset={1}>
            <FormGroup>
              <ControlLabel>
                Text <span className="text-danger"></span>
              </ControlLabel>
              <FormControl
                type="phone"
                name="phonenbr"
                placeholder="ex: +1555-161-9921 (include country code)"
                onChange={event => this.setState({ text: event.target.value })}
              />
              {this.state.textError}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1}>
            <FormGroup>
              <ControlLabel>
                Email <span className="text-danger"></span>
              </ControlLabel>
              <FormControl
                type="email"
                name="email"
                disabled
                placeholder="ex: sjohnson@tatler.com"
                onChange={event => this.setState({ email: event.target.value })}
              />
              {this.state.emailError}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Step1;
