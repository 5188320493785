import RequestService from 'components/ApiService/RequestService.js';
import AppConfig from 'components/AppConfig/AppConfig.js';
import AuthService from 'components/AuthService/AuthService.js';
import BatchSearchParams from '../../Models/BatchSearchParams.js';
import TelemetrySearchParams from '../../Models/TelemetrySearchParams.js';
import RegisteredEntitySearchParams from '../../Models/RegisteredEntitySearchParams.js';

export default class GlasschainHttpClient {
    // Initializing important variables
    constructor() {
        this.appConfig = new AppConfig();
        this.domain = this.appConfig.getCoreServiceHost(); //'https://gc-core-svc.azurewebsites.net' // API server domain
        this.requestService = new RequestService(this.domain);
        this.authService = new AuthService();
    }


    async pingCore() {
        try {
            let loggedInGcid = this.authService.getUser();;
            const url = '/api/dashboard/coreping';
            let result = await this.requestService.fetchJson(url);
            return (result.data == "ping");
        }
        catch (err) {
            console.log(err);
            return false;
        }
    }

    async pingCoreDb() {
        try {
            let loggedInGcid = this.authService.getUser();;
            const url = '/api/dashboard/coredbping';
            let result = await this.requestService.fetchJson(url);
            return (result.data == "ping");
        }
        catch (err) {
            console.log(err);
            return false; 
        }
    }

    async pingIbmFt() {
        try {
            let loggedInGcid = this.authService.getUser();;
            const url = '/api/dashboard/ibmftping';
            let result = await this.requestService.fetchJson(url);
            return (result.data == "ping");
        }
        catch (err) {
            console.log(err);
            return false; 
        }
    }


    async fetchGcidRegistry() {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/admin/cache/gcid/' + loggedInGcid + '/partnerconfigs/'
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchTelemetryLastAdapterEvent() {
        var searchParams = new TelemetrySearchParams();
        searchParams.maxCount = 1;
        searchParams.eventType = "shipmentpoll";
        try {
            let events = await this.fetchTelemetryListBySearchParams(searchParams);
            if (events.length > 0) {
                return events[0];
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchTelemetryLastEventWithCorePost() {
        var searchParams = new TelemetrySearchParams();
        searchParams.maxCount = 1;
        searchParams.eventType = "shipmentpoll";
        searchParams.didPostEvents = "true";
        try {
            let events = await this.fetchTelemetryListBySearchParams(searchParams);
            if (events.length > 0) {
                return events[0];
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchTelemetryLastUnsuccessfulEventWithCorePost() {
        var searchParams = new TelemetrySearchParams();
        searchParams.maxCount = 1;
        searchParams.eventType = "shipmentpoll";
        searchParams.didPostEvents = "true";
        searchParams.successful = "false";
        try {
            let events = await this.fetchTelemetryListBySearchParams(searchParams);
            if (events.length > 0) {
                return events[0];
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchTelemetryLastSuccessfulEventWithCorePost() {
        var searchParams = new TelemetrySearchParams();
        searchParams.maxCount = 1;
        searchParams.eventType = "shipmentpoll";
        searchParams.didPostEvents = "true";
        searchParams.successful = "true";
        try {
            let events = await this.fetchTelemetryListBySearchParams(searchParams);
            if (events.length > 0) {
                return events[0];
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchTelemetryListBySearchParams(telemetrySearchParams) {
        try {
            let loggedInGcid = this.authService.getUser();
            let qString = telemetrySearchParams.toQueryString();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/telemetry/pollingevent';
            var url = path + "?" + qString;
            let shipments = await this.requestService.fetchJson(url);
            return shipments.data;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchRegisteredEntityList(){
        try {
            let loggedInGcid = this.authService.getUser();
            const url = '/api/dashboard/gcid/' + loggedInGcid + '/ibmft/registeredentities';
            let registeredEntities = await this.requestService.fetchJson(url);
            return registeredEntities.data;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    downloadZippedAssets(correlationid) {
        // straight file download
        let loggedInGcid = this.authService.getUser();
        const path = '/api/dashboard/gcid/' + loggedInGcid + '/zipfile/' + correlationid;
        this.requestService.downloadFile(path);
    }

    async fetchZippedAssets(correlationid) {
        try {
            let loggedInGcid = this.authService.getUser();
            ///api/dashboard/gcid/om/zip/1070332
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/zip/' + correlationid;
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJsonFile(url, true, correlationid);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async postShipment(shipment) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/adapter/shipment';
            var url = path;
            let postResult = await this.requestService.postJson(url, shipment, true);
            return postResult;

        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }



    async fetchBatchStatusByHour(daysOld) {
        try {
            let loggedInGcid = this.authService.getUser();
            //let qString = "status=" + postStatus;
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/postresultbyhour/daysold/' + daysOld;
            var url = path; // + "?" + qString;
            let result = await this.requestService.fetchJson(url);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


    async fetchBatchListBySearchParams(batchSearchParams) {
        try {
            let loggedInGcid = this.authService.getUser();
            let qString = batchSearchParams.toQueryString();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/batchtrace/json';
            var url = path + "?" + qString;
            let shipments = await this.requestService.fetchJson(url);
            return shipments;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchLastPostedBatchTrace() {
        try {
            
            let result =  await this.fetchBatchByLastXCount("1");
            console.log("Last Posted: ");
            console.log(result);
            var dat = {data: result.data[0]};
            console.log(dat);
            return dat;
            /*
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/batchtrace/lastposted';
            var url = path;
            let lastPosted = await this.requestService.fetchJson(url);
            console.log("Last Posted: ");
            console.log(lastPosted);
            return lastPosted;
            */
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchBatchByLastXCount(lastX, retailer) {
        try {
            var searchParams = new BatchSearchParams();
            searchParams.maxCount = lastX;
            if ((retailer != "") && (retailer != "all")) {
                searchParams.retailerGcId = retailer;
            }
             var result = await this.fetchBatchListBySearchParams(searchParams);
             return result;
            /*
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/batchtrace/lastposted/count/';
            var url = path + lastX;
            if ((retailer != "") && (retailer != "all")) {
                url = url + "?retailergcid=" + retailer;
            }
            new 
            let shipments = await this.requestService.fetchJson(url);
            return shipments;
            */
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchBatchByLastXHours(lastX, retailer) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/adapter/gcid/' + loggedInGcid + '/shipment/posted/hoursago/';
            var url = path + lastX;
            if ((retailer != "") && (retailer != "all")) {
                url = url + "?retailergcid=" + retailer;
            }
            let shipments = await this.requestService.fetchJson(url);
            return shipments;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchBatchByLastXMinutes(lastX, retailer) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/shipment/posted/minutesago/';
            var url = path + lastX;
            if ((retailer != "") && (retailer != "all")) {
                url = url + "?retailergcid=" + retailer;
            }
            let shipments = await this.requestService.fetchJson(url);
            return shipments;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchBatchByOrderNbr(orderNbr) {
        try {
            var searchParams = new BatchSearchParams();
            searchParams.orderNbr = orderNbr;
             var result = await this.fetchBatchListBySearchParams(searchParams);
             return result;


            /*
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/batchtrace/';
            var url = path + orderNbr;
            let shipment = await this.requestService.fetchJson(url);
            var shipments = [];
            shipments.push(shipment.data);
            shipment.data = shipments;
            return shipment;
            */
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    *processData(tree) {
        if (!tree) { return; }

        for (var i = 0; i < tree.length; i++) {
            var nd = tree[i];
            yield nd;

            if (nd.children) {
                yield* this.processData(nd.children);
            }
        }
    }

    isSuccessfulStatus(status) {
        let cleanedStatus = status.toLowerCase().replace(' ', '');
        switch (cleanedStatus) {
            case '200':
            case 'tooearly':
            case 'ok':
            case 'created':
                return true;
            default:
                return false;
        }
    }

    isFtPostNode(docType) {
        return docType.startsWith('Ft');
    }

    getFailedShape() {
        return {
            shape: 'rect',
            shapeProps: {
                width: 20,
                height: 20,
                x: -10,
                y: -10,
                fill: 'red',
            }
        };
    }

    getSuccessfulShape() {
        return {
            shape: 'rect',
            shapeProps: {
                width: 20,
                height: 20,
                x: -10,
                y: -10,
                fill: 'green',
            }
        };
    }

    async fetchTraceTree(orderNbr) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/doctracetree/';
            var url = path + orderNbr;
            let traceTree = await this.requestService.fetchJson(url);
            // update trace tree with additional attributes for status
            var it = this.processData(traceTree.data.children);
            var res = it.next();
            while (!res.done) {
                if (this.isFtPostNode(res.value.docType)) {
                    if (this.isSuccessfulStatus(res.value.status)) {
                        res.value.nodeSvgShape = this.getSuccessfulShape();
                    }
                    else {
                        res.value.nodeSvgShape = this.getFailedShape();
                    }
                }
                // else default shape
                console.log(res.value);
                res = it.next();
            }

            return traceTree;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchSyntaxDoc(transactionId) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/dashboard/gcid/' + loggedInGcid + '/docTrace/';
            var url = path + transactionId;
            let syntaxDoc = await this.requestService.fetchJson(url);
            return syntaxDoc;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchPartnerInfo() {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/admin/cache/gcid/' + loggedInGcid + '/partnerconfigs';
            var url = path;
            let partnerInfo = await this.requestService.fetchJson(url);
            return partnerInfo.data.partnerships;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async fetchSubscriptionAlerts() {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/admin/alerts/gcid/' + loggedInGcid;
            var url = path;
            let alerts = await this.requestService.fetchJson(url);
            return alerts;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async postSubscriptionAlert(alertObj) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/admin/alerts/gcid/' + loggedInGcid + '/addalertrequest';
            var url = path;
            let alerts = await this.requestService.postJson(url, alertObj, true);
            return alerts;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }

    async removeSubscriptionAlert(alertId) {
        try {
            let loggedInGcid = this.authService.getUser();
            const path = '/api/admin/alerts/gcid/' + loggedInGcid + '/removealertrequest/alertid/'
            var url = path + alertId;
            let result = await this.requestService.postJson(url, '', true);
            return result;
        }
        catch (err) {
            console.log(err);
            throw new Error(err); // stupid. why not just let it flow? We might want to change it, though. 
        }
    }


}