/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Button,
  Modal,
  Navbar,
  Nav,
  NavDropdown,
  MenuItem,
  FormGroup,
  InputGroup,
  Collapse
} from "react-bootstrap";

import { withRouter } from "react-router-dom";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import NavBarLoginExpireTimer from "../Navbars/NavbarLoginExpireTimer.jsx";

import AuthService from "components/AuthService/AuthService.js";
import AppConfig from "components/AppConfig/AppConfig.js";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.appConfig = new AppConfig();

    this.state = {
      viewedNotificationItems: 0,
      showAbout: false,
      showRegistry: false,
      version: this.appConfig.getVersion()
    };

    this.auth = new AuthService();
    this.gcid = this.auth.getUser();
    this.userGuideUrl = this.appConfig.getUserGuideUrl();
    this.notificationCount = this.notificationCount.bind(this);
    this.onNotificationItemSelect = this.onNotificationItemSelect.bind(this);
    this.helpHandler = this.helpHandler.bind(this);
    this.handleAboutOpen = this.handleAboutOpen.bind(this);
    this.handleAboutClose = this.handleAboutClose.bind(this);
    this.toggleRegsitry = this.toggleRegistry.bind(this);
  }

  toggleRegistry(){
    if (!this.state.showAbout) return; // don't toggle if the box isn't open
    this.setState({showRegistry: !this.state.showRegistry});
  }

  handleAboutOpen() {
    this.setState({ showAbout: true });
  }

  handleAboutClose() {
    this.setState({ 
      showRegistry: false,
      showAbout: false 
    });
  }

  onNotificationItemSelect(ev) {
    let x = ev;
    console.log(x);
  }

  alertBell() {
    if (this.notificationCount() > 0) {
      return (
        <div>
          <i className="fa fa-bell-o" />
          <span
            className="notification"
            onClick={this.viewNotificationMenuItems.bind(this)}
          >
            {this.notificationCount()}
          </span>
          <p className="hidden-md hidden-lg">
            Notifications
            <b className="caret" />
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <i className="fa fa-bell-o" />
          <p className="hidden-md hidden-lg">
            Notifications
            <b className="caret" />
          </p>
        </div>
      );
    }
  }

  viewNotificationMenuItems() {
    // how many items are currently in there?
    let currItems = this.props.notificationMenuItems.length - 1;
    this.setState({ viewedNotificationItems: currItems });
  }

  draw;

  notificationCount() {
    if (this.props.notificationMenuItems == null) return 0;
    return (
      this.props.notificationMenuItems.length -
      this.state.viewedNotificationItems -
      1
    );
  }

  helpHandler(ev) {
    window.open(this.userGuideUrl, "_blank");
  }

  logoutHandler(ev) {
    this.auth.logout();
    this.props.history.push("/login-page");
  }

  render() {

    const registryDoc =  (this.props.registry) ? JSON.stringify(this.props.registry, null, "  ") : "{}";
    const timezone = (this.props.registry) ? this.props.registry.senderConfig.timeZoneDesignator : "";

    return (
      <div>
        <Modal show={this.state.showAbout} onHide={this.handleAboutClose}>
          <Modal.Header closeButton>
            <Modal.Title>About</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid>
              <Row>
                <Col xs={4} md={2}>
                  <img
                    src={require("../../assets/img/logos/glasschain-logo.png")}
                  />
                </Col>
                <Col xs={8} md={6}>
                  <div center="true">Glasschain IBMFT Dashboard</div>
                  <div center="true">Powered by Glassbit</div>
                  <div center="true">Version: {this.state.version} </div>
                  <div center="true">
                    Timezone: {timezone} &nbsp;<b className="caret" onClick={ () => this.setState({ showRegistry: true})}/>
                  </div>
                </Col>
              </Row>
              <Collapse in={this.state.showRegistry}>
              <Row>
                <Col xs={8} md={6}>
                  <div style={{ width: "100%", height: "100%" }}>
                  <SyntaxHighlighter
                    id="Syntax"
                    showLineNumbers={false}
                    wrapLines={true}
                    style={docco}
                    children={registryDoc}
                  />
                  </div>
                </Col>
              </Row>
              </Collapse>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleAboutClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <Navbar.Form pullRight className="navbar-search-form">
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <i className="fa fa-hourglass-3" />
              </InputGroup.Addon>
              <NavBarLoginExpireTimer
                expireTimer={this.logoutHandler.bind(this)}
              />
            </InputGroup>
          </FormGroup>
        </Navbar.Form>
        <Nav pullRight>
          <Navbar.Brand>
            <img
              src={require(`../../assets/img/logos/${this.gcid}-logo.png`)}
              style={{ height: 75, marginTop: -5 }}
              alt={this.gcid}
            />
          </Navbar.Brand>
          <NavDropdown
            eventKey={3}
            title={this.alertBell()}
            noCaret
            id="basic-nav-dropdown-2"
          >
            {this.props.notificationMenuItems &&
              this.props.notificationMenuItems.map(function(item, i) {
                return (
                  <MenuItem
                    eventKey={i}
                    //onSelect={this.onNotificationItemSelect.bind(this)}
                  >
                    {item}
                  </MenuItem>
                );
              })}
          </NavDropdown>

          <NavDropdown
            eventKey={5}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            <MenuItem eventKey={4.2} hr onClick={this.helpHandler}>
              <i className="pe-7s-help1" /> User Guide
            </MenuItem>

            <MenuItem eventKey={4.5} onClick={this.logoutHandler.bind(this)}>
              <div className="text-danger">
                <i className="pe-7s-close-circle" /> Log out
              </div>
            </MenuItem>
            <MenuItem eventKey={4.2} hr onClick={this.handleAboutOpen}>
              <i className="pe-7s-help1" /> About
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default withRouter(HeaderLinks);
